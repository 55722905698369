import react, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';
import PngIcons from './icons/PngIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'react-toastify/dist/ReactToastify.css';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import ReactDOM from 'react-dom'
import SignatureCanvas from 'react-signature-canvas'
import Draganddrop from './Draganddrop';
import { Field, Formik, useFormik } from 'formik';
import CustomDropDown from './CustomDropDown';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import AWS from 'aws-sdk';
import SignatureUpload from './SignatureUpload';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';



const Blogs = () => {

 //////////////////////////////////

//  const handleSignatureEnd = async (file) => {
//   console.log('file', file);
//   const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + file.lastModified;
//   const formData = new FormData();
//   formData.append('image', file, file.name);

//   try {
//     const response = await axios.post(url, formData);
//     console.log('UploadImage', response.data.data);
//     if (!formik.values.second_person_file) {
//       formik.setFieldValue('second_person_file', response.data.data);
//     } else {
//       console.log('error');
//     }
//   } catch (error) {
//     console.error('Error uploading file:', error);
//   }
// }





  //////////////////////////////////////


  const [loader, setLoader] = useState(false)

 


  const financialServicesGuidePdfPath = '/ANIG-FSG-Version-3-03.03.23-Nexus.pdf';
  const navigate = useNavigate()
  const signatureRef = useRef(null)
  const secondsignatureRef = useRef(null)
  const clickconstact = () => {
    navigate('/contact')

  }

  const [files,setFiles]=useState(null)
  const [secondselectedFile, setSecondSelectedFile] = useState(null)
  const [CurrentselectedFile, setCurrentSelectedFile] = useState(null)
  const inputRef = useRef(null);
  const secondinputRef = useRef(null)
  const currentinputRef = useRef(null)
  const formik = useFormik({
    initialValues: {
      SMSF: "",
      SMSFTYPE: "",
      email: "",
      companyName: null,
      first_name: "",
      first_name_persontwo: null,
      last_name: "",
      last_name_persontwo: null,
      date_of_birth: "",
      date_of_birth_persontwo: null,
      middleName: null,
      middleName_persontwo: null,
      TFN_persontwo: null,
      TFN: "",
      TITLE: "",
      TITLE_persontwo: null,
      existingfund: "",
      accountnumber: "",
      rolloverType: "",
      signature: "",
      second_person_signature:null,
      file: null,
      second_person_file: null,
      current_superannuation_fund_file: null

    },


  });
  useEffect(() => {


  }, [formik.values])

  console.log('formis', formik.values);
  const handleClear = () => {
    signatureRef.current.clear();
    formik.setFieldValue('signature', '');
  };




  const handleSignatureEnd = async () => {
    const signatureDataUrl = signatureRef.current.toDataURL();
  
    // Convert data URL to Blob
    const blob = await dataURLtoBlob(signatureDataUrl);
  
    // Create FormData object
    const formData = new FormData();
    formData.append('image', blob, 'signature.png');
  
    // Upload FormData to server
    try {
      const response = await axios.post("https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + Date.now(), formData);
      console.log('Signature uploaded successfully:', response.data);
    
      // Check if the signature already contains a URL
      if (formik.values.signature && formik.values.signature.startsWith('http')) {
        // If the signature field already contains a URL, set the response in second_person_signature
        formik.setFieldValue('second_person_signature', response.data.data);
      } else {
        // If the signature field doesn't contain a URL, set the response in signature
        formik.setFieldValue('signature', response.data.data);
      }
    } catch (error) {
      console.error('Error uploading signature:', error);
    }
  }    
  
  function dataURLtoBlob(dataUrl) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        resolve(xhr.response);
      };
      xhr.onerror = function() {
        reject(new Error('Failed to convert data URL to Blob'));
      };
      xhr.responseType = 'blob';
      xhr.open('GET', dataUrl);
      xhr.send();
    });
  }


  const handleSubmit = async () => {

    setLoader(true)
    const data = {
      companyName: formik.values.companyName,
      email: formik.values.email,




      tags: ["formA"],
      customField: {

        "n8IKmDpO5TnZL3dlYbk4": formik.values.first_name,
        "EPefprQr1onlKpHMUWpS": formik.values.first_name_persontwo,
        "0bNX9fc22KKnobqVHamM": formik.values.last_name,
        "I92Z9b4mDG0ySnZlytuN": formik.values.last_name_persontwo,
        "RmSxWb82vAC4obGaGQNA": formik.values.date_of_birth,
        "T3C5DZ5HaKkMaOO0uDmC": formik.values.date_of_birth_persontwo,
        "EpRyZJxGj2OY2kXdvC6l": formik.values.SMSF,
        "hJinD4nVi8ezy7FBzRwg": formik.values.SMSFTYPE,
        "0rxU1lO4axiocsb45sOq": formik.values.middleName,
        "483qExFPuUHO8FS8l2Pn": formik.values.middleName_persontwo,
        "e4c8dXk1GMeHV3uPnMxi": formik.values.TFN,
        "ZSqX8qQUQL8lipOX2rvf": formik.values.TFN_persontwo,
        "4AMQuZ1b296HoArKBlpY": formik.values.TITLE,
        "uYtCOaEVP2NOYx7QoPl5": formik.values.TITLE_persontwo,
        "dDhchxWtsXiqexn0rG85": formik.values.second_person_signature,




        "ieBl0tl4CO5Si8gxogE5": formik.values.accountnumber,
        "VuC4WKtVNsBDwkOtuJW8": formik.values.existingfund,
        "U72YidJXgRyzBoEzihIl": formik.values.rolloverType,
        "G3ooFsjc9PMlAroOgU7d": formik.values.signature,
        "CVB1TSCg0VIh81WZqGc2": formik.values.file,
        "X0ZO3kS7vDqE4vRzuTiw": formik.values.second_person_file,
        "oOlBZExzCn6fMr30Kinp": formik.values.current_superannuation_fund_file,


        "UTvNQqLmhSu7lg3HQHU0": await upload(),
      },
    };


    console.log('data', data);


    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://rest.gohighlevel.com/v1/contacts/',
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Ilp5ZFIwb0xXekY2VktROXZPWEdMIiwiY29tcGFueV9pZCI6ImxhUWpybGF4eEdTdXdmY2cyYjVsIiwidmVyc2lvbiI6MSwiaWF0IjoxNzA3MDg4Nzc0OTcyLCJzdWIiOiJ1c2VyX2lkIn0.BLIqrSTcynWl0EfXFUCzGs0OjP95iASGlYrmpHmlyOc'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        toast.success('Form submitted successfully', {
          position: 'top-center'
        });
        setLoader(false)
        window.location.reload();
      })
      .catch(function (error) {
        toast.error('Error: Problem in uploading the data', {
          position: 'top-center'
        });
        setLoader(false)
        console.log(error);
      });



  }

  const upload = async () => {
    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4"
    });
  
    // Constants for layout
    const pageWidth = pdf.internal.pageSize.width;
    const margin = 40;
    const lineHeight = 15;
    const maxLineWidth = pageWidth - 2 * margin;
    let verticalOffset = margin;
  
    // Function to add a new page if necessary
    const checkNewPage = (offset, additionalLines = 1) => {
        if (offset + (lineHeight * additionalLines) > pdf.internal.pageSize.height) {
            pdf.addPage();
            return margin; // Reset vertical offset to the top of the new page
        }
        return offset;
    };
  
    // Title for the PDF
    pdf.setFontSize(18);
    pdf.setFont("helvetica", "bold");
    pdf.text('SMSF Establishment Form Data', margin, verticalOffset);
    verticalOffset += 2 * lineHeight; // Increase offset after title
  
    // Sections to print
    const sections = [
        { title: 'New SMSF Details', data: {
            ['Name of the new SMSF']: `${formik?.values?.SMSF}`,
            ['SMSF Type']: formik?.values?.SMSFTYPE,
        }},
        { title: "Primary Contact's Details", data: {
            Email: formik?.values?.email,
        }},
        { title: 'Corporate Trustee Details', data: {
            ['Proposed Company Name']: formik?.values?.companyName,
        }},
        { title: 'Member/Trustee/Director 1', data: {
            ['Name']: formik?.values?.TITLE+" "+formik?.values?.first_name+" "+formik?.values?.middleName+" "+formik?.values?.last_name,
            ['DOB']: formik?.values?.date_of_birth,
            ['TFN']: formik?.values?.TFN,
        }},
        { title: 'Member/Trustee/Director 2', data: {
            ['Name']: formik?.values?.TITLE_persontwo+" "+formik?.values?.first_name_persontwo+" "+formik?.values?.middleName_persontwo+" "+formik?.values?.last_name_persontwo,
            ['DOB']: formik?.values?.date_of_birth_persontwo,
            ['TFN']: formik?.values?.TFN_persontwo,
        }},
        { title: 'SMSF Rollover', data: {
            ['Name of your existing fund']: formik?.values?.existingfund,
            ['Membership or account number']: formik?.values?.accountnumber,
            ['Rollover type']: formik?.values?.rolloverType,
        }}
    ];
  
    // Process each section
    sections.forEach(section => {
        verticalOffset = checkNewPage(verticalOffset, 4); // Ensure there's enough space for the title and at least one line of text
        pdf.setFontSize(16);
        pdf.setFont("helvetica", "bold");
        pdf.text(section.title, margin, verticalOffset);
        verticalOffset += lineHeight * 1.5; // Slightly larger gap after title
  
        pdf.setFontSize(12);
        pdf.setFont("helvetica", "normal");
        Object.entries(section.data).forEach(([key, value]) => {
            const lines = pdf.splitTextToSize(`${key}: ${value}`, maxLineWidth); // Split text to handle long lines
            lines.forEach(line => {
                verticalOffset = checkNewPage(verticalOffset);
                pdf.text(line, margin, verticalOffset);
                verticalOffset += lineHeight;
            });
        });
  
        verticalOffset += lineHeight * 2; // Add a larger space after each section
    });
  
    // Save the PDF with a dynamic name
    // pdf.save('form-data.pdf');
    const pdfBlob = pdf.output('blob');
  
    console.log('pdfBlob',pdfBlob);
    // Create a new FormData object and append the blob
    const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/"+formik?.values?.first_name;
    const formData = new FormData();
    formData.append("image", pdfBlob, "smsf-data.pdf");
  
      // const formData = new FormData();
      // formData.append('image', iterator, iterator.name);
      const response = await axios.post(url, formData);
      console.log('UploadImage', response);
      console.log('UploadImage', response.data.data);
      console.log('UploadImage', typeof response.data.data);
  
      return response.data.data
  };


  const [value, setValue] = React.useState('female');

  const newhandleChange = (event) => {
    setValue(event.target.value);
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <div className='w-100' id='formcontainer' >
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container className='navbarmain' fluid >

            <Navbar.Brand href="#"><img className='logoimg' style={{
              width: '167px',
              //height: '40px'
            }} src={PngIcons.logo} /></Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0 gap"
                style={{ maxHeight: '100px', marginLeft: 'auto', gap: '58px' }}
                navbarScroll
              >
                <Nav.Link className='nav' href="/">Home

                </Nav.Link>

                <Nav.Link className='nav' href="/about">About Us

                </Nav.Link>

                <Nav.Link style={{ borderBottom: '2px solid  #FE5200' }} className='nav' href="/assesment">Advisory

                </Nav.Link>


                <Nav.Link className='nav' href="/service">Books

                </Nav.Link>


              </Nav>
              <Form >


                <Button onClick={clickconstact} className='signin'  >Contact Us</Button>
                {/* <div className='signin'> Free Assessment</div> */}

              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div>

          <div className='first_div' style={{ width: '100%', margin: 'auto', marginTop: '-8px' }}>
            <div className='aboutus'>
              SMSF Establishment Form
            </div>
            <div className='home'>Home  <span className='span' > {'>'}   SMSF Establishment Form</span></div>
            <div>
              <img className='tiktik' src={PngIcons.tiktik} />

            </div>

          </div>


          <div className='newsenddiv' style={{ width: '49%', margin: 'auto', marginTop: '-8px', paddingTop: '60px', margin: 'auto' }}>


            <div className='newdetails'>
              New SMSF Details
            </div>


            <div >
              <div>
                <div className='name'>Name of the new SMSF*</div>
                <div >

                  <input value={formik.values.SMSF}
                    onChange={(e) => formik.setValues({ ...formik.values, SMSF: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'> Including middle name(s) if applicable</p>
                </div>
              </div>

              <div className='mo'>
                This field is necessary but if you are unsure, please <br></br>
                write “Not Sure” and we will contact you to discuss.<br></br>
                Can be your name of choice but needs to end with:<br></br>
                ‘Superannuation Fund’; ‘Super Fund’; ‘Pension Fund’; or <br></br>
                ‘Retirement Fund’. Ideally, the word ‘The’ should not be <br></br>
                included in the name.

              </div>
            </div>

            <div style={{ marginTop: '48px' }}>
              <div >
                <div className='name'>SMSF Type *</div>
              </div>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  //value={value}
                  //onChange={newhandleChange}
                  value={formik.values.SMSFTYPE}
                  onChange={(e) => formik.setValues({ ...formik.values, SMSFTYPE: e.target.value })}
                >
                  <FormControlLabel value="SMSF with a Corporate Trustee" control={<Radio />} label="SMSF with a Corporate Trustee" />
                  <FormControlLabel value="SMSF with Individual Trustees" control={<Radio />} label="SMSF with Individual Trustees" />
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ marginTop: '69px' }} className='newdetails'>
              Primary Contact's Details
            </div>



            <div>
              <div className='name'>Blacksalt Account Email Address *</div>
              <div >

                <input value={formik.values.email}
                  onChange={(e) => formik.setValues({ ...formik.values, email: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                <div className='mo'>So that we can link this form to your existing Blacksalt</div>

              </div>
            </div>

            <div>
  {formik.values.SMSFTYPE !== "SMSF with Individual Trustees" && (
    <>
      <div style={{ marginTop: '53px' }} className='newdetails'>
        Corporate Trustee Details
      </div>
      <div style={{ marginTop: '13px', marginBottom: '13px' }} className='applicable'>SMSF Trustee Company – The sole purpose of this company is to act as a trustee of a
        regulated superannuation fund within the meaning of s19 of the Superannuation Industry
        Supervision Act 1993. The company’s constitution must have a clause prohibiting the company
        from distributing income or property to its members shareholders and may qualify for a
        reduced annual ASIC levy.
      </div>
 
  <div>
    <div className='name'>Proposed Company Name *</div>
    <div>
      <input
        value={formik.values.companyName}
        onChange={(e) => formik.setValues({ ...formik.values, companyName: e.target.value })}
        style={{ width: '310px', height: '40px' }}
        type="text"
      />
      <div className='mo'>So that we can link this form to your existing Blacksalt</div>
    </div>
  </div>
  </>
  )}
</div>


            <div style={{ marginTop: '53px' }} className='newdetails'>
              Member/Trustee/Director 1
            </div>

            <div style={{ marginTop: '13px', marginBottom: '13px' }} className='applicable'>Note: The first Member/Director will default as the Chairperson and Public Officer for
              meeting minutes etc.</div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Title *</div>
                <div >

                  <input value={formik.values.TITLE}
                    onChange={(e) => formik.setValues({ ...formik.values, TITLE: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />
                  <div className='mo'>Mr, Mrs, Ms, etc.</div>
                </div>
              </div>

              <div >
                <div className='name'>First name(s) *</div>
                <div >

                  <input value={formik.values.first_name}
                    onChange={(e) => formik.setValues({ ...formik.values, first_name: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Middle name(s)</div>
                <div >

                  <input value={formik.values.middleName}
                    onChange={(e) => formik.setValues({ ...formik.values, middleName: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>

              <div >
                <div className='name'>Last name(s)*</div>
                <div >

                  <input value={formik.values.last_name}
                    onChange={(e) => formik.setValues({ ...formik.values, last_name: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div className='name'>DOB *</div>
              <div >

                <input value={formik.values.date_of_birth}
                  onChange={(e) => formik.setValues({ ...formik.values, date_of_birth: e.target.value })} style={{ width: '37%', height: '40px' }} type="date" />








              </div>
            </div>

            <div style={{ marginTop: '48px' }}>
              <div className='name'>TFN *</div>
              <div >

                <input value={formik.values.TFN}
                  onChange={(e) => formik.setValues({ ...formik.values, TFN: e.target.value })} style={{ width: '37%', height: '40px' }} type="number" />








              </div>
            </div>

            <div style={{ marginTop: '28px' }} className='applicable'>
              Certified copy of photo ID. This is a legal requirement and must be certified by a Justice <br></br> of the
              Peace (JP).
            </div>


            <div>

              {/* <input style={{ width: '100%', height: '163px' }} type="text" /> */}
              



              <div>
              {console.log('files',formik.values.file)}
  {/* {formik.values.file ? (
 

    <div style={{fontSize:'20px', color:'green'}}>File upload successfully </div>



  ) : ( */}

    <Draganddrop
      type="file"
      formik={formik}
      multiple
      onChange={(e) => {
        const file = e.target.files[0];
        setFiles([file]); 
      }}
      hidden="hidden"
      accept="*/*"
      inputRef={inputRef}
    />
  {/* )} */}
</div>


            </div>


            {/* ////secondpersondetail           */}




            <div style={{ marginTop: '53px' }} className='newdetails'>
              Member/Trustee/Director 2
              <div className='applicable'>If required.</div>
            </div>



            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Title </div>
                <div >

                  <input value={formik.values.TITLE_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, TITLE_persontwo: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />
                  <div className='mo'>Mr, Mrs, Ms, etc.</div>
                </div>
              </div>

              <div >
                <div className='name'>First name(s)</div>
                <div >

                  <input value={formik.values.first_name_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, first_name_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Middle name(s)</div>
                <div >

                  <input value={formik.values.middleName_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, middleName_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>

              <div >
                <div className='name'>Last name(s)</div>
                <div >

                  <input value={formik.values.last_name_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, last_name_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div className='name'>DOB</div>
              <div >

                <input value={formik.values.date_of_birth_persontwo}
                  onChange={(e) => formik.setValues({ ...formik.values, date_of_birth_persontwo: e.target.value })}
                  style={{ width: '37%', height: '40px' }} type="date" />








              </div>
            </div>

            <div style={{ marginTop: '48px' }}>
              <div className='name'>TFN </div>
              <div >

                <input value={formik.values.TFN_persontwo}
                  onChange={(e) => formik.setValues({ ...formik.values, TFN_persontwo: e.target.value })} style={{ width: '37%', height: '40px' }} type="number" />








              </div>
            </div>

            <div style={{ marginTop: '28px' }} className='applicable'>
              Certified copy of photo ID. This is a legal requirement and must be certified by a Justice <br></br> of the
              Peace (JP).
            </div>


            <div>

              {/* <input style={{ width: '100%', height: '163px' }} type="text" /> */}
              <div>
              {console.log('files',formik.values.second_person_file)}
  {/* {formik.values.second_person_file ? (
 

    <div style={{fontSize:'20px', color:'green'}}>File upload successfully </div>



  ) : ( */}
    <Draganddrop
      type="file"
      formik={formik}
      multiple
      onChange={(e) => {
        const second_person_file = e.target.secondselectedFile;
        setSecondSelectedFile([second_person_file]); 
      }}
      hidden="hidden"
      accept="*/*"
      inputRef={secondinputRef}
    />

  
  {/* )} */}
</div>

            </div>



            <div style={{ marginTop: '53px' }} className='newdetails'>SMSF Rollover</div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Name of your existing fund*</div>
                <div >

                  <input value={formik.values.existingfund}
                    onChange={(e) => formik.setValues({ ...formik.values, existingfund: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <div className='mo'>Eg. Hostplus, AustralianSuper, Qsuper, etc.</div>

                </div>
              </div>

              <div >
                <div className='name'>Membership or account number *</div>
                <div >

                  <input value={formik.values.accountnumber}
                    onChange={(e) => formik.setValues({ ...formik.values, accountnumber: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <div className='mo'>This can usually be found via a recent statement from <br></br> your fund.</div>

                </div>
              </div>
            </div>



            <div style={{ marginTop: '48px' }}>
              <div >
                <div className='name'>Rollover type *</div>
              </div>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={value}
                  // onChange={newhandleChange}
                  value={formik.values.rolloverType}
                  onChange={(e) => formik.setValues({ ...formik.values, rolloverType: e.target.value })}
                >
                  <FormControlLabel value="Full Rollover" control={<Radio />} label="Full Rollover" />
                  <FormControlLabel value="Partial Rollover" control={<Radio />} label="Partial Rollover" />
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ marginTop: '28px' }} className='applicable'>
              Recent statement from your current superannuation fund
            </div>


            <div>

              {/* <input style={{ width: '100%', height: '163px' }} type="text" /> */}
              <div>
              {console.log('files',formik.values.current_superannuation_fund_file)}
  {formik.values.current_superannuation_fund_file? (
 

    <div style={{fontSize:'20px', color:'green'}}>File upload successfully </div>



  ) : (
    <Draganddrop
      type="file"
      formik={formik}
      multiple
      onChange={(e) => {
        const current_superannuation_fund_file = e.target.CurrentselectedFile;
        setCurrentSelectedFile([current_superannuation_fund_file]); 
      }}
      hidden="hidden"
      accept="*/*"
      inputRef={currentinputRef}
    />

  
  )}
</div>

            </div>



            <div style={{ marginTop: '53px' }} className='newdetails'>Client Agreement & Authorisation
            </div>

            <div style={{ marginTop: '40px' }} className='name'>
              I declare that the information in this Application is true and correct at the time of
              completion;
            </div>

            <div style={{ marginTop: '20px' }} className='name'>
              I declare that I have never been convicted of an offence involving dishonesty, have never
              been subject to a civil penalty order under the SIS Act, am not an undischarged bankrupt
              and have not been disqualified by a regulator.
            </div>


            <div style={{ marginTop: '20px' }} className='name'>
              I agree that Blacksalt Group may collect, use and store my personal information for the
              purposes of processing this application and to provide ongoing services;
            </div>


            <div style={{ marginTop: '20px' }} className='name'>
              I consent to the role of member and/or office holder as noted in this application and
              authorise Blacksalt Group to register the Company on my/our behalf and act as the ASIC
              registered agent unless advised otherwise. I agree to the release of information between
              Blacksalt Group and my adviser or their firm, if details of an adviser have been provided or
              if the application was submitted by the adviser;
            </div>

            <div style={{ marginTop: '18px' }} className='name'>
              I authorise Blacksalt Group to apply to register the SMSF with the ATO.
            </div>

            <div style={{ marginTop: '18px' }} className='name'>
              I acknowledge that the service provided by Blacksalt Group is a documentation service
              only and does not constitute advice;
            </div>


            <div style={{ marginTop: '18px' }} className='name'>
              I acknowledge that Blacksalt Group does not provide Investment Advice and has not
              provided a recommendation that this fund be established;
            </div>

            <div style={{ marginTop: '18px' }} className='name'>I confirm that I have made my own decision to establish this fund based on my personal
              and financial circumstances and have obtained financial, legal or other advice where
              necessary.</div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '64px' }}>
              <div>
                <div className='name'>Signature - Member/Trustee/Director *</div>
                <div style={{ border: '1px solid black', marginTop: '33px' }}>
                  

<SignatureCanvas
      penColor="green"
      canvasProps={{ width: 300, height: 114, className: 'sigCanvas' }}
      ref={signatureRef}
      onEnd={handleSignatureEnd}
    />



                 


                </div>

                <div onClick={handleClear} className='clearbtn'>Clear</div>
              </div>

              <div >
                <div className='name'>Signature - Member/Trustee/Director 2 <br></br> (if required) </div>
                <div 
                
              style={{ border: '1px solid black' }}>

                


<SignatureCanvas
      penColor="green"
      canvasProps={{ width: 300, height: 114, className: 'sigCanvas' }}
      ref={secondsignatureRef}
      onEnd={handleSignatureEnd}
    />

</div>

               
                <div onClick={(e) => { e.stopPropagation(); window.location.reload(); }} className='clearbtn'>Clear</div>
              </div>
            </div>


            <div style={{ marginTop: '93px' }} className='newdetails'>Next Steps
            </div>

            <div style={{ marginTop: '37px' }} className='name'>
              After proceeding, Blacksalt will review your application within 48 hours. Once approved,
              Blacksalt will issue an invoice that requires payment before the SMSF can be lodged.
            </div>
            <div style={{ marginTop: '15px' }} className='name'>
              <span className='spanbold'>SMSF with a Corporate Trustee:</span> $2,750 (including GST)

            </div>

            <div className='name'>
              <span className='spanbold'>SMSF with Individual Trustee(s):</span> $1,650 (including GST)
            </div>
            <div style={{ marginTop: '15px' }} className='name'>
              Price includes all statutory charges, documentation, ABN/TFN application, and 2 x rollover
              applications.
            </div>
            <div style={{ marginTop: '15px' }} className='name'>
              Prefer to pay with cryptocurrency? Contact us to find out how.
            </div>
            <div style={{ marginTop: '15px' }} className='name'>
              Please note that the costs of the establishment can be reimbursed from your new SMSF
              once the rollover process is complete. Ask us how!
            </div>

            <div onClick={handleSubmit} style={{ marginTop: '84px', cursor: 'pointer' }} className='formsubmit'>
              {/* Submit */}
              {loader ? <div className="d-flex align-items-center gap-8"><img src={'loading2.gif'} width={20} height={20} />{'Submit'}</div>  : 'Submit'}
            </div>
          </div>








          <div style={{ marginTop: '75px' }} className='footer-div'>

            <MDBFooter className='text-center text-lg-start text-muted'>
              <section className>


              </section>

              <section className=''>
                <MDBContainer >

                  <MDBRow  >
                    <div className='dblock' style={{ display: 'flex', gap: '176px' }}>
                      <div style={{ width: '50%' }}>
                        <MDBCol >
                          <h6 className=' mb-4'>
                            <MDBIcon />
                            <img style={{
                              width: '227px',

                            }} src={PngIcons.footerlogo} />
                          </h6>
                          {/* <p className='footerpara'>
              Future Financial Wealth T/As  Nexus Financial Advisors 
Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)
              </p> */}
                          <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.facebook} />
                            </div>
                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.twitter} />
                            </div>

                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.likedin} />
                            </div>
                          </div>
                        </MDBCol>
                      </div>
                      <div className='dblock textleft newgap' style={{ display: 'flex', gap: '190px' }}>

                        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' >
                          <h6 className='footertext'>Sitemap</h6>

                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Home
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Services
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Assessment
                          </p>

                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />Reviews
                          </p>



                        </MDBCol>

                        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                          <h6 className=' footertext'>Info</h6>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            About Us
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Blogs
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Contact
                          </p>





                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                          <h6 className='footertext'>Security</h6>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            FAQ
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Terms & Conditions
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Privacy Policy
                          </p>

                        </MDBCol>
                      </div>
                    </div>

                    {/* <div className='newfooterpara'>
          <div>ABN: 86 663 229 847

            <div>ACN: 663 229 847</div>
          </div>

          <div style={{ marginTop: '30px', marginBottom: '30px' }}>

          <a
href={financialServicesGuidePdfPath}
target="_blank"
rel="noopener noreferrer"
style={{ textDecoration: 'underline', color: 'blue' }}
>
Financial Services Guide
</a>
          </div>
          <p>Future Financial Wealth T/As  Nexus Financial Advisors </p>
          


          <p>Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)</p>


        </div> */}
                  </MDBRow>

                </MDBContainer>
              </section>


            </MDBFooter>

          </div>


        </div>
      </div>



      <ToastContainer
      />
    </>
  )
}


export default Blogs;
import react, { useState, useRef, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';
import PngIcons from './icons/PngIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import Draganddrop from './Draganddrop';
import { Field, Formik, useFormik } from 'formik';
import CustomDropDown from './CustomDropDown';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const Blogs = ({ formikValues }) => {

  const formRef = useRef(null);

  const signatureRef = useRef(null)
  const financialServicesGuidePdfPath = '/ANIG-FSG-Version-3-03.03.23-Nexus.pdf';
  const navigate = useNavigate()
  const inputRef = useRef(null);

  const clickconstact = () => {
    navigate('/contact')

  }

const [files,setFiles]=useState(null)


  const formik = useFormik({
    initialValues: {
      SMSF: "",
      //SMSFTYPE: "",
      email: "",
      //companyName: "",
      phone: null,
      street_address: "",
      sameaddress: null,
      city: "",
      cityofbirth: "",
      Country_of_Birth: "",
      state: "",
      zipcode: "",
      country: "",
      annual_income: "",
      Other_income: null,
      occupation: "",
      Total_children: null,
      Total_children_dependant: null,
      Superannuation: null,
      SMSF_name: null,
      first_name: "",
      first_name_persontwo :null,
      last_name: "",
      last_name_persontwo:null,
      date_of_birth: "",
      date_of_birth_persontwo :null,
   
      TFN_persontwo:null,
      TFN: null,

      Home_Valuation: null,
      Home_Debt_Loan: null,
      Property_Valuation: null,
      Property_Debt_Loan: null,
      Vehicles_Valuation: null,
      Vehicles_Debt_Loan: null,
      Direct_Investments_Worth: null,
      Managed_Investments_Worth: null,
      Credit_Card_Limit: null,
      Credit_Card_Debt: null,
      Other_Assets_Worth: null,
      Other_Debt_Liabilities_Loans: null,
      further_information: null,
      hearaboutus: "",
      file: "",
      second_person_Email:null,
      second_person_Phone:null,
      second_person_Address:null,
      second_person_City:null,
      second_person_State:null,
      second_person_zipcode:null,
      second_person_Country:null,
      second_person_same_address:null,
      second_person_city_of_birth:null,
      second_person_country_of_birth:null,
      second_person_annual_income:null,
      second_person_other_income:null,
      second_person_occupation:null

    },


  });
  useEffect(() => {




  }, [formik.values])
  console.log('formis', formik.values);

  const handleClear = () => {
    signatureRef.current.clear();
    formik.setFieldValue('signature', '');
  };

  const [loader, setLoader] = useState(false)

  const handleSubmit = async () => {
    setLoader(true);

    const data = {
      //companyName: formik.values.companyName,
      email: formik.values.email,
      phone: formik.values.phone,
      // street_address1: formik.values.street_address,
      city: formik.values.city,
      state: formik.values.state,

      country: formik.values.country,




      tags: ["formB"],
      customField: {
        "OA2EMGGojZ8dGMXwIxuo": formik.values.street_address,
        "n8IKmDpO5TnZL3dlYbk4": formik.values.first_name,
        "EPefprQr1onlKpHMUWpS":formik.values.first_name_persontwo,
        "0bNX9fc22KKnobqVHamM": formik.values.last_name,
        "I92Z9b4mDG0ySnZlytuN":formik.values.last_name_persontwo, 
        "RmSxWb82vAC4obGaGQNA": formik.values.date_of_birth,
        "MeJYfUz3iCeylC6wJDUG": formik.values.zipcode,

        "zGAk2cvzJM6HMCccaBws": formik.values.sameaddress,
        "00R0esMVUAZGDI4BGXhZ": formik.values.cityofbirth,
        "rjCVKAbW8WhgNe2PDamn": formik.values.Country_of_Birth,
        "LgKQ2xvAGOS9IPN0ZKQk": formik.values.annual_income,
        "A1GWZCHFCJbOt6ybipos": formik.values.Other_income,
        "vu3ekMVecQtoEzKELCSk": formik.values.occupation,




        "04IZFgIjlveQGzrcnymr": formik.values.Total_children,
        "1FkVwkSb5zirUxJoXp7p": formik.values.Total_children_dependant,
        "EpRyZJxGj2OY2kXdvC6l": formik.values.SMSF,
        "YwVlWoeOI1u20HWkyxtX": formik.values.Superannuation,
        "JkD0hZrvKf6vOPpE4pFd": formik.values.SMSF_name,



        "6OyqL1brPD27sC7dTL6C": formik.values.Home_Valuation,
        "dL91w3562tWwIKlmLWam": formik.values.Home_Debt_Loan,
        "ExuDf0qq3l6ACLt6dt7e": formik.values.Property_Valuation,
        "GRp4BrkqPbmr18KpdXm7": formik.values.Property_Debt_Loan,
        "Q2QPOzdEAA5e7lbC1cgy": formik.values.Vehicles_Valuation,
        "L6hAiFScuBPAG85OD1WY": formik.values.Vehicles_Debt_Loan,
        "8bgo6C2fJQqNSlrkiEbK": formik.values.Direct_Investments_Worth,
        "lKpTkrq2QpbQuDRhph6j": formik.values.Managed_Investments_Worth,
        "M2YVhumm2aQ735NCZ3OL": formik.values.Credit_Card_Limit,
        "OgNxTBHMMBZIy6s80Weg": formik.values.Credit_Card_Debt,
        "oa52UXIeH8pSDFO3ut6j": formik.values.Other_Assets_Worth,
        "S5xE4Gz6qC9kVdKgecZW": formik.values.Other_Debt_Liabilities_Loans,
        "ESfWKZO8VnDtgujpaaXM": formik.values.further_information,
        "OFT4X4jxy4H9LIwtmdNc": formik.values.hearaboutus,




          "T3C5DZ5HaKkMaOO0uDmC":formik.values.date_of_birth_persontwo,
    
        "e4c8dXk1GMeHV3uPnMxi": formik.values.TFN,
          "ZSqX8qQUQL8lipOX2rvf": formik.values.TFN_persontwo,
      
    "vpNavBCSEcnAt0fbJFpL": formik.values.second_person_Email,
        "y6bKunvf6x1dUML2Hqc1": formik.values.second_person_Phone,
         "E8xBiAqSCprkjMcJR43G": formik.values.second_person_Address,
         "oGTBvbgxm7TtGiDxIb3L": formik.values.second_person_City,

            "PvQRzJjwajfxPUqVFCm7": formik.values.second_person_State,
        "wNY1bVoufoiajrhTN0Il": formik.values.second_person_zipcode,
         "ldWPQOJhgy3F6BHVjCv9": formik.values.second_person_Country,
         "OlN5p2SldRiwL2w8FR3O": formik.values.second_person_same_address,

            "dfjtYVBqfrG8U7wsmvRY": formik.values.second_person_city_of_birth,
        "OvjitmNs9fbu6Q0AWflo": formik.values.second_person_country_of_birth,
         "SeAbaMRUL8st2wMUveIH": formik.values.second_person_annual_income,
         "qhpSGuqM3LUSJsSpleeu": formik.values.second_person_other_income,
         "4l2GtXJks05OxErNEmXg": formik.values.second_person_occupation,

     
        "CVB1TSCg0VIh81WZqGc2": formik.values.file,
        "UTvNQqLmhSu7lg3HQHU0": await upload(),



      },
    };


    console.log('data', data);


    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://rest.gohighlevel.com/v1/contacts/',
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Ilp5ZFIwb0xXekY2VktROXZPWEdMIiwiY29tcGFueV9pZCI6ImxhUWpybGF4eEdTdXdmY2cyYjVsIiwidmVyc2lvbiI6MSwiaWF0IjoxNzA3MDg4Nzc0OTcyLCJzdWIiOiJ1c2VyX2lkIn0.BLIqrSTcynWl0EfXFUCzGs0OjP95iASGlYrmpHmlyOc'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setLoader(false)
        toast.success('Form submitted successfully', {
          position: 'top-center'
        });
    
        // window.location.reload();
      })
      .catch(function (error) {
        setLoader(false)
        toast.error('Error: Problem in uploading the data', {
          position: 'top-center'
        });
        console.log(error);
      });



  }

  const handleSubmita = async (event) => {
    event.preventDefault();
    setLoader(true);
    const formElement = formRef.current; // Get the form element

    // Generate canvas from form
    html2canvas(formElement, { scale: 1 }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [canvas.width, canvas.height]
        });

        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
        pdf.save('download.pdf'); // Save the PDF
        setLoader(false);
    }).catch(err => {
        toast.error('Error generating PDF');
        setLoader(false);
        console.error('Error generating PDF', err);
    });
};


const handleSubmitt = async (event) =>{
  event.preventDefault();
  const formElement = formRef.current;
  html2canvas(formElement).then((canvas) => {
    canvas.toBlob(async(blob) => {
        const file = new File([blob], "form-outpuut.pdf", { type: "application/pdf" });
        const formData1 = new FormData();
        formData1.append("file", file);
        console.log('file',file);

        const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + file.name.replace(/\s/g, "");
          const formData = new FormData();
          formData.append('image', file, file.name);
          const response = await axios.post(url, formData);
          console.log('UploadImage', response);
          console.log('UploadImage', response.data.data);
          console.log('UploadImage', typeof response.data.data);

    }, 'image/png');

    setLoader(false);
}).catch((error) => {
    toast.error('Error generating PDF: ' + error.message);
    setLoader(false);
});
}





const handleSubmitzx = async (event) => {
  
  try {
    event.preventDefault();
  const formElement = formRef.current;

  // Capture the entire form
  const canvas = await html2canvas(formElement, {
    scale: 2, // Increase scale for better quality
    useCORS: true,
    logging: true,
    allowTaint: true,
  });

  // PDF page size (A4 in this example)
  const pageWidth = 595.28;
  const pageHeight = 841.89;

  // Calculate scaling factor to fit width
  const scale = pageWidth / canvas.width;
const scaledHeight = canvas.height * scale;
const aspectRatio = canvas.width / canvas.height;

  // Create PDF
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'pt',
    format: 'a4'
  });

  // Calculate number of pages needed
  const numPages = Math.ceil(scaledHeight / pageHeight);

  for (let i = 0; i < numPages; i++) {
    if (i > 0 ) pdf.addPage();
    
    // Calculate the portion of the image to use for this page
    const srcY = i * (pageHeight / scale);
    const srcHeight = Math.min(pageHeight / scale, canvas.height - srcY);
    console.log(`Page ${i + 1}: srcY = ${srcY}, srcHeight = ${srcHeight}`);

    pdf.addImage(
      canvas,
      'PNG',
      0,
      (pageHeight - (pageWidth / aspectRatio)) / 2, // Center vertically
      pageWidth,
      pageWidth / aspectRatio, // Use calculated aspect ratio for height
      null,
      'FAST',
      0,
      srcY / canvas.height,
      1,
      srcHeight / canvas.height
    );
  }

  // Generate blob and create file
  const pdfBlob = pdf.output('blob');
  const file = new File([pdfBlob], "forma-goutputabcd.pdf", { type: "application/pdf" });

    // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height, null, 'FAST');

    // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    // const pdfBlob = pdf.output('blob');
    
    // const file = new File([pdfBlob], "form-outpuot.pdf", { type: "application/pdf" });
    const formData = new FormData();
    formData.append("file", file);
    
    const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + file.name.replace(/\s/g, "");
    const response = await axios.post(url, formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('UploadImage', response);
    console.log('UploadImage', response.data.data);
    console.log('UploadImage', typeof response.data.data);
    
    setLoader(false);
  } catch (error) {
    toast.error('Error generating PDF: ' + error.message);
    setLoader(false);
  }
};
const handleSubmitas = async (event) => {
  event.preventDefault();
  setLoader(true);
  const formElement = formRef.current;
  
  try {
    // const canvas = await html2canvas(formElement);
    const canvas = await html2canvas(formElement, {
      scale: 2, // Increase scale for better quality
      useCORS: true,
      logging: true,
      allowTaint: true,
    });
    const imgData = canvas.toDataURL('image/png');

    const pageWidth = 395.28;
  const pageHeight = 541.89;
  

  // Calculate the number of pages
  const aspectRatio = canvas.width / canvas.height;
  const pdfWidth = pageWidth;
  const pdfHeight = pdfWidth / aspectRatio;
  const numPages = Math.ceil(pdfHeight / pageHeight);

    const pdf = new jsPDF({
      // orientation: 'portrait',
      // unit: 'px',
      // format: [canvas.width, canvas.height]
      orientation: 'portrait',
      unit: 'px',
      format: [canvas.width, canvas.height],
      compress: true
    });

    for (let i = 0; i < numPages; i++) {
      if (i >0 && i <1 ) pdf.addPage();
  
      // Source area of the canvas
      const srcY = i * pageHeight * (canvas.height / pdfHeight);
      const srcHeight = Math.min(pageHeight * (canvas.height / pdfHeight), canvas.height - srcY);
  
      // Destination area on the PDF page
      const destY = 0;
      const destHeight = Math.min(pageHeight, pdfHeight - (i * pageHeight));
  
      pdf.addImage(
        canvas, 
        'PNG', 
        0, destY, pdfWidth, destHeight, 
        null,
        'FAST',
        0,
        srcY / canvas.height,
        1,
        srcHeight / canvas.height
      );
    }
  
    // Generate blob and create file
    const pdfBlob = pdf.output('blob');
    const file = new File([pdfBlob], "forma-outpuaata.pdf", { type: "application/pdf" });
  
    
    // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height, null, 'FAST');

    // pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);
    // const pdfBlob = pdf.output('blob');
    
    // const file = new File([pdfBlob], "form-outpuot.pdf", { type: "application/pdf" });
    const formData = new FormData();
    formData.append("file", file);
    
    const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + file.name.replace(/\s/g, "");
    const response = await axios.post(url, formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('UploadImage', response);
    console.log('UploadImage', response.data.data);
    console.log('UploadImage', typeof response.data.data);
    
    setLoader(false);
  } catch (error) {
    toast.error('Error generating PDF: ' + error.message);
    setLoader(false);
  }
};

const upload = async () => {
  const pdf = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4"
  });

  // Constants for layout
  const pageWidth = pdf.internal.pageSize.width;
  const margin = 40;
  const lineHeight = 15;
  const maxLineWidth = pageWidth - 2 * margin;
  let verticalOffset = margin;

  // Function to add a new page if necessary
  const checkNewPage = (offset, additionalLines = 1) => {
      if (offset + (lineHeight * additionalLines) > pdf.internal.pageSize.height) {
          pdf.addPage();
          return margin; // Reset vertical offset to the top of the new page
      }
      return offset;
  };

  // Title for the PDF
  pdf.setFontSize(18);
  pdf.setFont("helvetica", "bold");
  pdf.text('Financial Snapshot Form Data', margin, verticalOffset);
  verticalOffset += 2 * lineHeight; // Increase offset after title

  // Sections to print
  const sections = [
      { title: 'Person 1 Details', data: {
          Name: `${formik?.values?.first_name} ${formik?.values?.last_name}`,
          DOB: formik?.values?.date_of_birth,
          TFN: formik?.values?.TFN,
          Email: formik?.values?.email,
          Phone: formik?.values?.phone,
          Address: `${formik?.values?.street_address} ${formik?.values?.city} ${formik?.values?.state} ${formik?.values?.zipcode} ${formik?.values?.country}`,
          ["Is Person 1's postal address the same as the residential address?"]: formik?.values?.sameaddress,
          ['Town/City of Birth']: formik?.values?.cityofbirth,
          ['Country of Birth']: formik?.values?.Country_of_Birth,
          ['Gross annual income']: formik?.values?.annual_income,
          ['Other income']: formik?.values?.Other_income,
          Occupation: formik?.values?.occupation,
      }},
      { title: 'Person 2 Details', data: {
          Name: `${formik?.values?.first_name_persontwo} ${formik?.values?.last_name_persontwo}`,
          DOB: formik?.values?.date_of_birth_persontwo,
          TFN: formik?.values?.TFN_persontwo,
          Email: formik?.values?.second_person_Email,
          Phone: formik?.values?.second_person_Phone,
          Address: `${formik?.values?.second_person_Address} ${formik?.values?.second_person_City} ${formik?.values?.second_person_State} ${formik?.values?.second_person_zipcode} ${formik?.values?.second_person_Country}`,
          ["Is Person 2's postal address the same as the residential address?"]: formik?.values?.second_person_same_address,
          ['Town/City of Birth']: formik?.values?.second_person_city_of_birth,
          ['Country of Birth']: formik?.values?.second_person_country_of_birth,
          ['Gross annual income']: formik?.values?.second_person_annual_income,
          ['Other income']: formik?.values?.second_person_other_income,
          Occupation: formik?.values?.second_person_occupation,
      }},
      { title: 'Other Personal Details', data: {
          ['Total children']: formik?.values?.Total_children,
          ['Total children still dependant']: formik?.values?.Total_children_dependant,
          ['Do you have a SMSF?']: formik?.values?.SMSF,
          ['Total in Superannuation']: formik?.values?.Superannuation,
          ['Superannuation fund or SMSF name']: formik?.values?.SMSF_name,
      }},
      { title: 'Assets & Liabilities', data: {
          ['Owner Occupied Home Valuation']: formik?.values?.Home_Valuation,
          ['Owner Occupied Home Debt/Loan']: formik?.values?.Home_Debt_Loan,
          ['Total Investment Property(s) Valuation']: formik?.values?.Property_Valuation,
          ['Total Investment Property(s) Debt/Loan']: formik?.values?.Property_Debt_Loan,
          ['Total Vehicles(s) Valuation']: formik?.values?.Vehicles_Valuation,
          ['Total Vehicles(s) Debt/Loan']: formik?.values?.Vehicles_Debt_Loan,
          ['Total Direct Investments Worth']: formik?.values?.Direct_Investments_Worth,
          ['Total Managed Investments Worth']: formik?.values?.Managed_Investments_Worth,
          ['Total Credit Card Limits']: formik?.values?.Credit_Card_Limit,
          ['Total Credit Card Debt']: formik?.values?.Credit_Card_Debt,
          ['Total Other Assets Worth']: formik?.values?.Other_Assets_Worth,
          ['Total Other Debt/Liabilities/Loans']: formik?.values?.Other_Debt_Liabilities_Loans,
          ['Any further information']: formik?.values?.further_information,
          ['How did you hear about us?']: formik?.values?.hearaboutus,
      }}
  ];

  // Process each section
  sections.forEach(section => {
      verticalOffset = checkNewPage(verticalOffset, 4); // Ensure there's enough space for the title and at least one line of text
      pdf.setFontSize(16);
      pdf.setFont("helvetica", "bold");
      pdf.text(section.title, margin, verticalOffset);
      verticalOffset += lineHeight * 1.5; // Slightly larger gap after title

      pdf.setFontSize(12);
      pdf.setFont("helvetica", "normal");
      Object.entries(section.data).forEach(([key, value]) => {
          const lines = pdf.splitTextToSize(`${key}: ${value}`, maxLineWidth); // Split text to handle long lines
          lines.forEach(line => {
              verticalOffset = checkNewPage(verticalOffset);
              pdf.text(line, margin, verticalOffset);
              verticalOffset += lineHeight;
          });
      });

      verticalOffset += lineHeight * 2; // Add a larger space after each section
  });

  // Save the PDF with a dynamic name
  // pdf.save('form-data.pdf');
  const pdfBlob = pdf.output('blob');

  console.log('pdfBlob',pdfBlob);
  // Create a new FormData object and append the blob
  const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/"+formik?.values?.first_name;
  const formData = new FormData();
  formData.append("image", pdfBlob, "form-data.pdf");

    // const formData = new FormData();
    // formData.append('image', iterator, iterator.name);
    const response = await axios.post(url, formData);
    console.log('UploadImage', response);
    console.log('UploadImage', response.data.data);
    console.log('UploadImage', typeof response.data.data);

    return response.data.data
};


const upload1 = async () => {
  const pdf = new jsPDF();

  // Constants for layout
  const pageHeight = pdf.internal.pageSize.height;
  const margin = 20;
  const lineHeight = 10;
  let verticalOffset = margin; // Initial vertical offset for entries
  const maxLineWidth = 180; // Maximum line width for text wrapping

  // Function to add a new page if necessary
  const checkNewPage = (offset, additionalLines = 1) => {
    if (offset + (lineHeight * additionalLines) > pageHeight) {
      pdf.addPage();
      return margin; // Reset vertical offset to the top of the new page
    }
    return offset;
  };

  // Title for the PDF
  pdf.text('Financial Snapshot Form', margin, verticalOffset);
  verticalOffset += 2 * lineHeight; // Increase offset after title

  // Sections to print
  const sections = [
    { title: 'Person 1 Details', data: {
      Name: formik?.values?.first_name+" "+formik?.values?.last_name,
      DOB: formik?.values?.date_of_birth,
      TFN: formik?.values?.TFN,
      Email: formik?.values?.email,
      Phone: formik?.values?.phone,
      Address: formik?.values?.street_address+" "+formik?.values?.city+" "+formik?.values?.state+" "+formik?.values?.zipcode+" "+formik?.values?.country,
      ["Is Person 1's postal address the same as the residential address?"]: formik?.values?.sameaddress,
      ['Town/City of Birth']: formik?.values?.cityofbirth,
      ['Country of Birth']: formik?.values?.Country_of_Birth,
      ['Gross annual income']: formik?.values?.annual_income,
      ['Other income']: formik?.values?.Other_income,
      Occupation: formik?.values?.occupation,
    }},
    { title: 'Person 2 Details', data: {
      Name: formik?.values?.first_name_persontwo+" "+formik?.values?.last_name_persontwo,
      DOB: formik?.values?.date_of_birth_persontwo,
      TFN: formik?.values?.TFN_persontwo,
      Email: formik?.values?.second_person_Email,
      Phone: formik?.values?.second_person_Phone,
      Address: formik?.values?.second_person_Address+" "+formik?.values?.second_person_City+" "+formik?.values?.second_person_State+" "+formik?.values?.second_person_zipcode+" "+formik?.values?.second_person_Country,
      ["Is Person 2's postal address the same as the residential address?"]: formik?.values?.second_person_same_address,
      ['Town/City of Birth']: formik?.values?.second_person_city_of_birth,
      ['Country of Birth']: formik?.values?.second_person_country_of_birth,
      ['Gross annual income']: formik?.values?.second_person_annual_income,
      ['Other income']: formik?.values?.second_person_other_income,
      Occupation: formik?.values?.second_person_occupation,
    }},
    { title: 'Other Personal Details', data: {
      ['Total children']: formik?.values?.Total_children,
      ['Total children still dependant']: formik?.values?.Total_children_dependant,
      ['Do you have a SMSF?']: formik?.values?.SMSF,
      ['Total in Superannuation']: formik?.values?.Superannuation,
      ['Superannuation fund or SMSF name']: formik?.values?.SMSF_name,
    }},
    { title: 'Assets & Liabilities', data: {
      ['Owner Occupied Home Valuation']: formik?.values?.Home_Valuation,
      ['Owner Occupied Home Debt/Loan']: formik?.values?.Home_Debt_Loan,
      ['Total Investment Property(s) Valuation']: formik?.values?.Property_Valuation,
      ['Total Investment Property(s) Debt/Loan']: formik?.values?.Property_Debt_Loan,
      ['Total Vehicles(s) Valuation']: formik?.values?.Vehicles_Valuation,
      ['Total Vehicles(s) Debt/Loan']: formik?.values?.Vehicles_Debt_Loan,
      ['Total Direct Investments Worth']: formik?.values?.Direct_Investments_Worth,
      ['Total Managed Investments Worth']: formik?.values?.Managed_Investments_Worth,
      ['Total Credit Card Limits']: formik?.values?.Credit_Card_Limit,
      ['Total Credit Card Debt']: formik?.values?.Credit_Card_Debt,
      ['Total Other Assets Worth']: formik?.values?.Other_Assets_Worth,
      ['Total Other Debt/Liabilities/Loans']: formik?.values?.Other_Debt_Liabilities_Loans,
      ['Any further information']: formik?.values?.further_information,
      // Documents: 'Jane Doe',
      ['How did you hear about us?']: formik?.values?.hearaboutus,
    }}
  ];

  // Process each section
  sections.forEach(section => {
    verticalOffset = checkNewPage(verticalOffset);
    pdf.text(section.title, margin, verticalOffset);
    verticalOffset += lineHeight;

    Object.entries(section.data).forEach(([key, value]) => {
      const lines = pdf.splitTextToSize(`${key}: ${value}`, maxLineWidth); // Split text to handle long lines
      lines.forEach(line => {
        verticalOffset = checkNewPage(verticalOffset);
        pdf.text(line, margin, verticalOffset);
        verticalOffset += lineHeight;
      });
    });

    verticalOffset += lineHeight; // Add some space after each section
  });

  // Save the PDF with a dynamic name
  pdf.save('form-data.pdf');
  const pdfBlob = pdf.output('blob');

  console.log('pdfBlob',pdfBlob);
  // Create a new FormData object and append the blob
  const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/"+formik?.values?.first_name;
  const formData = new FormData();
  formData.append("image", pdfBlob, "form-data.pdf");

    // const formData = new FormData();
    // formData.append('image', iterator, iterator.name);
    const response = await axios.post(url, formData);
    console.log('UploadImage', response);
    console.log('UploadImage', response.data.data);
    console.log('UploadImage', typeof response.data.data);

    return response.data.data
};

  const [value, setValue] = React.useState('female');

  const newhandleChange = (event) => {
    setValue(event.target.value);
  };
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
    
      <div className='w-100' id='formcontainer' >
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container className='navbarmain' fluid >

            <Navbar.Brand href="#"><img className='logoimg' style={{
              width: '167px',
              //height: '40px'
            }} src={PngIcons.logo} /></Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0 gap"
                style={{ maxHeight: '100px', marginLeft: 'auto', gap: '58px' }}
                navbarScroll
              >
                <Nav.Link className='nav' href="/">Home

                </Nav.Link>

                <Nav.Link className='nav' href="/about">About Us

                </Nav.Link>

                <Nav.Link style={{ borderBottom: '2px solid  #FE5200' }} className='nav' href="/assesment">Advisory

                </Nav.Link>


                <Nav.Link className='nav' href="/service">Books

                </Nav.Link>


              </Nav>
              <Form >


                <Button onClick={clickconstact} className='signin'  >Contact Us</Button>
                {/* <div className='signin'> Free Assessment</div> */}

              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>

          <div className='first_div' style={{ width: '100%', margin: 'auto', marginTop: '-8px' }}>
            <div className='aboutus'>
              Financial Snapshot Form
            </div>
            <div className='home'>Home  <span className='span' > {'>'}  Financial Snapshot Form</span></div>
            <div>
              <img className='tiktik' src={PngIcons.tiktik} />

            </div>

          </div>
        <form ref={formRef} onSubmit={handleSubmit} >



          <div className='newsenddiv' style={{ width: '49%', margin: 'auto', marginTop: '-8px', paddingTop: '60px', margin: 'auto' }}>


            <div className='newdetails'>
              Person 1 Details
            </div>


            <div style={{ display: 'flex', gap: '30px' }}>
              <div>
                <div className='name'>First name(s)*</div>
                <div >

                  <input value={formik.values.first_name}
                    onChange={(e) => formik.setValues({ ...formik.values, first_name: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'> Including middle name(s) if applicable</p>
                </div>
              </div>

              <div >
                <div className='name'>Last name(s)*</div>
                <div >

                  <input value={formik.values.last_name}
                    onChange={(e) => formik.setValues({ ...formik.values, last_name: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'>Full surname/last name</p>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Date of birth*</div>
                <div >

                  <input value={formik.values.date_of_birth}
                    onChange={(e) => formik.setValues({ ...formik.values, date_of_birth: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="Date" placeholder='DD-MM-YYYY' />

                </div>
              </div>

              <div >
                <div className='name'>Tax File Number (TFN)</div>
                <div >

                  <input value={formik.values.TFN}
                    onChange={(e) => formik.setValues({ ...formik.values, TFN: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />


                </div>
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Email address*</div>
                <div >

                  <input value={formik.values.email}
                    onChange={(e) => formik.setValues({ ...formik.values, email: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'> Please use the same email address that you used to
                  </p>
                  <p className='newmo'> initially contact us so that we can link this data to your
                  </p>
                  <p className='newmo'> Blacksalt user profile.
                  </p>

                </div>
              </div>

              <div >
                <div className='name'>Phone number</div>
                <div >

                  <input
                    value={formik.values.phone} onChange={(e) => formik.setValues({ ...formik.values, phone: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div className='name'>Residential address *</div>
              <div >

                <input value={formik.values.street_address} onChange={(e) => formik.setValues({ ...formik.values, street_address: e.target.value })} style={{ width: '100%', height: '40px' }} type="text" />
                <p className='mo'> Street Address
                </p>

                <div style={{ display: 'flex', gap: '30px', marginTop: '24px' }}>
                  <div>

                    <div >

                      <input value={formik.values.city} onChange={(e) => formik.setValues({ ...formik.values, city: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'> City
                      </p>



                    </div>
                  </div>

                  <div >

                    <div >

                      <input value={formik.values.state} onChange={(e) => formik.setValues({ ...formik.values, state: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'>  State
                      </p>

                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', gap: '30px', marginTop: '24px' }}>
                  <div>

                    <div >

                      <input value={formik.values.zipcode} onChange={(e) => formik.setValues({ ...formik.values, zipcode: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'> Postal / Zip Code
                      </p>



                    </div>
                  </div>

                  <div >

                    <div >

                      <FormControl sx={{ m: 0, minWidth: 312, height: '40px' }} size="small">
                        <InputLabel id="demo-select-small-label"></InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          //value={formik.values.country}
                          onChange={(e) => formik.setValues({ ...formik.values, country: e.target.value })}
                        >
                          <MenuItem value="">

                          </MenuItem>
                          <MenuItem value={'Australia'}>Australia</MenuItem>
                          <MenuItem value={'UK'}>UK</MenuItem>
                          <MenuItem value={'Canada'}>Canada</MenuItem>
                        </Select>
                      </FormControl>

                      <p className='mo'>  Country
                      </p>

                    </div>
                  </div>
                </div>






              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div >
                <div className='name'>Is Person 1's postal address the same as the residential address?</div>
              </div>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={value}
                  // onChange={newhandleChange}
                  value={formik.values.sameaddress}
                  onChange={(e) => formik.setValues({ ...formik.values, sameaddress: e.target.value })}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Town/City of Birth *</div>
                <div >

                  <input value={formik.values.cityofbirth}
                    onChange={(e) => formik.setValues({ ...formik.values, cityofbirth: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>

              <div >
                <div className='name'>Country of Birth * </div>
                <div >

                  <input value={formik.values.Country_of_Birth}
                    onChange={(e) => formik.setValues({ ...formik.values, Country_of_Birth: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Gross annual income (before tax) *</div>
                <div >

                  <input value={formik.values.annual_income}
                    onChange={(e) => formik.setValues({ ...formik.values, annual_income: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>

              <div >
                <div className='name'>Other income (before tax)</div>
                <div >

                  <input value={formik.values.Other_income}
                    onChange={(e) => formik.setValues({ ...formik.values, Other_income: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Occupation*</div>
                <div >

                  <input
                    value={formik.values.occupation}
                    onChange={(e) => formik.setValues({ ...formik.values, occupation: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>


            </div>
          </div>


          <div className='newsenddiv' style={{ width: '49%', margin: 'auto', marginTop: '-8px', paddingTop: '64px', margin: 'auto' }}>


            {/* ////// persin 2 detail */}



            <div className='newdetails'>
              Person 2 Details

              <div className='applicable'>
                (if applicable)
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px' }}>
              <div>
                <div className='name'>First name(s)</div>
                <div >

                  <input     value={formik.values.first_name_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, first_name_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'> Including middle name(s) if applicable</p>
                </div>
              </div>

              <div >
                <div className='name'>Last name(s)</div>
                <div >

                  <input  value={formik.values.last_name_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, last_name_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'>Full surname/last name</p>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Date of birth</div>
                <div >

                  <input  value={formik.values.date_of_birth_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values, date_of_birth_persontwo: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="Date" placeholder='DD-MM-YYYY' />

                </div>
              </div>

              <div >
                <div className='name'>Tax File Number (TFN)</div>
                <div >

                  <input  value={formik.values.TFN_persontwo}
                    onChange={(e) => formik.setValues({ ...formik.values,TFN_persontwo: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />


                </div>
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Email address</div>
                <div >

                  <input  value={formik.values.second_person_Email}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_Email: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                  <p className='mo'> Please use the same email address that you used to
                  </p>
                  <p className='newmo'> initially contact us so that we can link this data to your
                  </p>
                  <p className='newmo'> Blacksalt user profile.
                  </p>

                </div>
              </div>

              <div >
                <div className='name'>Phone number</div>
                <div >

                  <input  value={formik.values.second_person_Phone}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_Phone: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div className='name'>Residential address</div>
              <div >

                <input  value={formik.values.second_person_Address}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_Address: e.target.value })} style={{ width: '100%', height: '40px' }} type="text" />
                <p className='mo'> Street Address
                </p>

                <div style={{ display: 'flex', gap: '30px', marginTop: '24px' }}>
                  <div>

                    <div >

                      <input  value={formik.values.second_person_City}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_City: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'> City
                      </p>



                    </div>
                  </div>

                  <div >

                    <div >

                      <input  value={formik.values.second_person_State}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_State: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'>  State
                      </p>

                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', gap: '30px', marginTop: '24px' }}>
                  <div>

                    <div >

                      <input  value={formik.values.second_person_zipcode}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_zipcode: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />
                      <p className='mo'> Postal / Zip Code
                      </p>



                    </div>
                  </div>

                  <div >

                    <div >

                      <FormControl sx={{ m: 0, minWidth: 312, height: '40px' }} size="small">
                        <InputLabel id="demo-select-small-label"></InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                        
                          onChange={(e) => formik.setValues({ ...formik.values, second_person_Country: e.target.value })}
                         
                        >
                          <MenuItem value="">

                          </MenuItem>
                          <MenuItem value={'Australia'}>Australia</MenuItem>
                          <MenuItem value={'UK'}>UK</MenuItem>
                          <MenuItem value={'Canda'}>Canda</MenuItem>
                        </Select>
                      </FormControl>

                      <p className='mo'>  Country
                      </p>

                    </div>
                  </div>
                </div>






              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div >
                <div className='name'>Is Person 2's postal address the same as the residential address?</div>
              </div>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={value}
                  // onChange={newhandleChange}
                  onChange={(e) => formik.setValues({ ...formik.values, second_person_same_address: e.target.value })}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Town/City of Birth</div>
                <div >

                  <input  value={formik.values.second_person_city_of_birth}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_city_of_birth: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>

              <div >
                <div className='name'>Country of Birth</div>
                <div >

                  <input value={formik.values.second_person_country_of_birth}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_country_of_birth: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Gross annual income (before tax)</div>
                <div >

                  <input value={formik.values.second_person_annual_income}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_annual_income: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>

              <div >
                <div className='name'>Other income (before tax)</div>
                <div >

                  <input value={formik.values.second_person_other_income}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_other_income: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Occupation</div>
                <div >

                  <input value={formik.values.second_person_occupation}
                    onChange={(e) => formik.setValues({ ...formik.values, second_person_occupation: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>


            </div>
          </div>


          <div style={{ width: '49%', margin: 'auto', marginTop: '-8px', paddingTop: '64px', margin: 'auto' }}>
            <div className='personal'>
              Other Personal Details
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '29px' }}>
              <div>
                <div className='name'>Total children</div>
                <div >

                  <input value={formik.values.Total_children}
                    onChange={(e) => formik.setValues({ ...formik.values, Total_children: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="number" />

                </div>
              </div>

              <div >
                <div className='name'>Total children still dependant</div>
                <div >

                  <input value={formik.values.Total_children_dependant}
                    onChange={(e) => formik.setValues({ ...formik.values, Total_children_dependant: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />


                </div>
              </div>
            </div>
            <div style={{ marginTop: '48px' }}>
              <div >
                <div className='name'>Do you have a SMSF? *</div>
              </div>

              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={value}
                  // onChange={newhandleChange}
                  value={formik.values.SMSF}
                  onChange={(e) => formik.setValues({ ...formik.values, SMSF: e.target.value })}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total in Superannuation</div>
                <div >

                  <input value={formik.values.Superannuation}
                    onChange={(e) => formik.setValues({ ...formik.values, Superannuation: e.target.value })} className='dowdate' style={{ width: '310px', height: '40px' }} type="text" />

                </div>
              </div>

              <div >
                <div className='name'>Superannuation fund or SMSF name</div>
                <div >

                  <input value={formik.values.SMSF_name}
                    onChange={(e) => formik.setValues({ ...formik.values, SMSF_name: e.target.value })} style={{ width: '310px', height: '40px' }} type="text" />


                </div>
              </div>
            </div>
          </div>




          <div style={{ width: '49%', margin: 'auto', marginTop: '-8px', paddingTop: '64px', margin: 'auto' }}>
            <div className='newdetails'>
              Assets & Liabilities
              <div className='applicable'>
                Please complete your current Assets and Liabilities as a combination of what both parties own.<br></br>
                Do not include any Assets invested via your superannuation fund as this is accounted for <br></br>
                separately.
              </div>
            </div>


            <div style={{ display: 'flex', gap: '30px' }}>
              <div>
                <div className='name'>Owner Occupied Home Valuation</div>
                <div >

                  <input value={formik.values.Home_Valuation}
                    onChange={(e) => formik.setValues({ ...formik.values, Home_Valuation: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'> Amount can be approx, such as $1,000,000</p>
                </div>
              </div>

              <div >
                <div className='name'>Owner Occupied Home Debt/Loan</div>
                <div >

                  <input value={formik.values.Home_Debt_Loan}
                    onChange={(e) => formik.setValues({ ...formik.values, Home_Debt_Loan: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Amount of money currently owing on your home</p>

                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total Investment Property(s) Valuation</div>
                <div >

                  <input value={formik.values.Property_Valuation}
                    onChange={(e) => formik.setValues({ ...formik.values, Property_Valuation: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'> Accumulative valuation of all investment properties</p>
                </div>
              </div>

              <div >
                <div className='name'>Total Investment Property(s) Debt/Loan</div>
                <div >

                  <input value={formik.values.Property_Debt_Loan}
                    onChange={(e) => formik.setValues({ ...formik.values, Property_Debt_Loan: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Accumulative owing debt of all investment properties</p>

                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total Vehicles(s) Valuation</div>
                <div>

                  <input value={formik.values.Vehicles_Valuation}
                    onChange={(e) => formik.setValues({ ...formik.values, Vehicles_Valuation: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'> Valuation of all vehicles such as cars, motorbikes,</p>
                  <p style={{ marginBottom: '2px' }} className='mo'>boats etc</p>
                </div>
              </div>

              <div >
                <div className='name'>Total Vehicles(s) Debt/Loanh</div>
                <div >

                  <input value={formik.values.Vehicles_Debt_Loan}
                    onChange={(e) => formik.setValues({ ...formik.values, Vehicles_Debt_Loan: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Total owning debt on all vehicles</p>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total Direct Investments Worth</div>
                <div>

                  <input value={formik.values.Direct_Investments_Worth}
                    onChange={(e) => formik.setValues({ ...formik.values, Direct_Investments_Worth: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'> Such as owned stocks, commodities, digital assets, etc</p>
                </div>
              </div>

              <div >
                <div className='name'>Total Managed Investments Worth</div>
                <div >

                  <input value={formik.values.Managed_Investments_Worth}
                    onChange={(e) => formik.setValues({ ...formik.values, Managed_Investments_Worth: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Investments with managed funds, hedge funds, etc</p>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total Credit Card Limits</div>
                <div>

                  <input value={formik.values.Credit_Card_Limit}
                    onChange={(e) => formik.setValues({ ...formik.values, Credit_Card_Limit: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'> Accumulative credit card limits for Person 1 and</p>
                  <p className='mo'>Person 2 combined</p>
                </div>
              </div>

              <div >
                <div className='name'>Total Credit Card Debt</div>
                <div >

                  <input value={formik.values.Credit_Card_Debt}
                    onChange={(e) => formik.setValues({ ...formik.values, Credit_Card_Debt: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Accumulative credit card debt owning for Person 1</p>
                  <p className='mo'>and Person 2 combined</p>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '30px', marginTop: '48px' }}>
              <div>
                <div className='name'>Total Other Assets Worth</div>
                <div>

                  <input value={formik.values.Other_Assets_Worth}
                    onChange={(e) => formik.setValues({ ...formik.values, Other_Assets_Worth: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Such as cash, art, and other valuables</p>
                </div>
              </div>

              <div >
                <div className='name'>Total Other Debt/Liabilities/Loans</div>
                <div >

                  <input value={formik.values.Other_Debt_Liabilities_Loans}
                    onChange={(e) => formik.setValues({ ...formik.values, Other_Debt_Liabilities_Loans: e.target.value })} style={{ width: '310px', height: '40px' }} type="number" />
                  <p className='mo'>Such as personal loans, business loans, etc</p>

                </div>
              </div>
            </div>


            <div style={{ marginTop: '48px' }}>
              <div>
                <div className='name'>Please include any further information below that you believe may assist us in understanding <br></br>
                  your current financial position.</div>
                <div>

                  <input value={formik.values.further_information}
                    onChange={(e) => formik.setValues({ ...formik.values, further_information: e.target.value })} style={{ width: '100%', height: '163px' }} type="text" />

                </div>
              </div>


            </div>

            <div style={{ marginTop: '69px' }}>


              <div className='newdetails'>
                Documents
                <div className='applicable'>
                  This step is optional, but it significantly helps Blacksalt understand your financial situation in
                  detail so that we can assist you the best we can.
                </div>
                <div style={{ marginTop: '28px' }} className='applicable'>
                  Upload a copy of your last lodged income tax returns and financial statements for all business
                  and personal entities.
                </div>
              </div>

              
              <div>
              {console.log('files',formik.values.file)}
  {formik.values.file ? (
 

    <div style={{fontSize:'20px', color:'green'}}>File upload successfully </div>



  ) : (
    <Draganddrop
      type="file"
      formik={formik}
      multiple
      onChange={(e) => {
        console.log('asdasdasd');
        const file = e.target.files;
        console.log('e.target.files',e.target.files);
        setFiles([file]); 
      }}
      hidden="hidden"
      accept="*/*"
      inputRef={inputRef}
    />
  )}
</div>




            </div>


            <div style={{ width: '100%', height: "1px", borderBottom: '1px solid #E6E6E6', paddingTop: '53px', paddingBottom: '53px' }}></div>


            <div className='name'>
              How did you hear about us? *
            </div>

            <div style={{ marginBottom: '78px' }}>
              <input value={formik.values.hearaboutus}
                  onChange={(e) => formik.setValues({ ...formik.values, hearaboutus: e.target.value })}  type="text" />
              {/* <FormControl sx={{ m: 0, minWidth: 312, height: '40px' }} size="small">
                <InputLabel id="demo-select-small-label"></InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  // value={formik.values}
                  onChange={(e) => formik.setValues({ ...formik.values, hearaboutus: e.target.value })}
                >
                  <MenuItem value="">

                  </MenuItem>
                  <MenuItem value={'Facebook'}>Facebook</MenuItem>
                  <MenuItem value={'Twitter'}>Twitter</MenuItem>
                  <MenuItem value={'Instagram'}>Instagram</MenuItem>
                  <MenuItem value={'Linkedin'}>Linkedin</MenuItem>
                </Select>
              </FormControl> */}


            </div>



            <div style={{ cursor: 'pointer' }} onClick={handleSubmit} className='formsubmit'>
              {/* Submit */}
              {loader ? <div className="d-flex align-items-center gap-8"><img src={'loading2.gif'} width={20} height={20} />{'Submit'}</div>  : 'Submit'}
            </div>
          </div>




        </form>
          <div style={{ marginTop: '75px' }} className='footer-div'>

            <MDBFooter className='text-center text-lg-start text-muted'>
              <section className>


              </section>

              <section className=''>
                <MDBContainer >

                  <MDBRow  >
                    <div className='dblock' style={{ display: 'flex', gap: '176px' }}>
                      <div style={{ width: '50%' }}>
                        <MDBCol >
                          <h6 className=' mb-4'>
                            <MDBIcon />
                            <img style={{
                              width: '227px',

                            }} src={PngIcons.footerlogo} />
                          </h6>
                          {/* <p className='footerpara'>
              Future Financial Wealth T/As  Nexus Financial Advisors 
Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)
              </p> */}
                          <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.facebook} />
                            </div>
                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.twitter} />
                            </div>

                            <div>
                              <img style={{
                                width: '48px',
                                height: '48px'
                              }} src={PngIcons.likedin} />
                            </div>
                          </div>
                        </MDBCol>
                      </div>
                      <div className='dblock textleft newgap' style={{ display: 'flex', gap: '190px' }}>

                        <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' >
                          <h6 className='footertext'>Sitemap</h6>

                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Home
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Services
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Assessment
                          </p>

                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />Reviews
                          </p>



                        </MDBCol>

                        <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                          <h6 className=' footertext'>Info</h6>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            About Us
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Blogs
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Contact
                          </p>





                        </MDBCol>

                        <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                          <h6 className='footertext'>Security</h6>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            FAQ
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' />
                            Terms & Conditions
                          </p>
                          <p className='footerpara'>
                            <MDBIcon color='secondary' icon='home' /> Privacy Policy
                          </p>

                        </MDBCol>
                      </div>
                    </div>

                    {/* <div className='newfooterpara'>
          <div>ABN: 86 663 229 847

            <div>ACN: 663 229 847</div>
          </div>

          <div style={{ marginTop: '30px', marginBottom: '30px' }}>

          <a
href={financialServicesGuidePdfPath}
target="_blank"
rel="noopener noreferrer"
style={{ textDecoration: 'underline', color: 'blue' }}
>
Financial Services Guide
</a>
          </div>
          <p>Future Financial Wealth T/As  Nexus Financial Advisors </p>
          


          <p>Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)</p>


        </div> */}
                  </MDBRow>

                </MDBContainer>
              </section>


            </MDBFooter>

          </div>
      </div>
      <ToastContainer
      />
    </>
  )
}


export default Blogs;
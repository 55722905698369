import logo from './logo.svg';
import './App.css';
import Main from './Components/Main';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/pages/Global.scss';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import About from './Components/About';
import Service from './Components/Service';
import Assement from './Components/Assement';
import Review from './Components/Review';
import Contact from './Components/Contact';
import Blogs from './Components/Blogs';
// import ContactC from './Components/ContactC';
// import { PDFViewer } from '@react-pdf/renderer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route exact path="/"
            element={
              <Main />
            }
          />
          <Route exact path="/about"
            element={
              <About />
            }
          />
          <Route exact path="/service"
            element={
              <Service />
            }
          />
          <Route exact path="/assesment"
            element={
              <Assement />
            }
          />

          <Route exact path="/SMSF-Establishment-Form"
            element={
              <Review />
            }
          />


          <Route exact path="/contact"
            element={
              <Contact />
            }
          />
          {/* <Route exact path="/contactc"
            element={
              // <PDFViewer>
                <ContactC />
              // </PDFViewer>
            }
          /> */}

<Route exact path="/Financial-Snapshot-Form"
            element={
              <Blogs />
            }
          />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;

import react, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';
import PngIcons from './icons/PngIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import Carousel from 'react-bootstrap/Carousel';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';


const Assement = () => {
    const financialServicesGuidePdfPath = '/ANIG-FSG-Version-3-03.03.23-Nexus.pdf';
    const navigate =useNavigate()

    const clickconstact =()=>{
      navigate('/contact')
  
    }

    return (
        <div className='w-100' id='assesmentcontainer' >

<Navbar expand="lg" className="bg-body-tertiary">
        <Container className='navbarmain' fluid >

          <Navbar.Brand href="#"><img className='logoimg' style={{
            width: '167px',
            //height: '40px'
          }} src={PngIcons.logo} /></Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 gap"
              style={{ maxHeight: '100px', marginLeft: 'auto', gap: '58px' }}
              navbarScroll
            >
              <Nav.Link  className='nav' href="/">Home

              </Nav.Link>

              <Nav.Link  className='nav' href="/about">About Us

              </Nav.Link>

              <Nav.Link  className='nav' href="/assesment">Advisory

              </Nav.Link>
     

              <Nav.Link style={{ borderBottom: '2px solid  #FE5200' }} className='nav' href="/service">Books

              </Nav.Link>
        

            </Nav>
            <Form >
         

              <Button onClick={clickconstact}  className='signin'  >Contact Us</Button>
              {/* <div className='signin'> Free Assessment</div> */}

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

            <div>
            <div className='first_div' style={{ width: '100%', margin: 'auto',marginTop:'-8px' }}>
          <div className='aboutus'>
          Stress-free books for your business.
          </div>
          <div className='home'>Home  <span className='span' > {'>'}   Books</span></div>
          <div>
                                <img className='tiktik' src={PngIcons.tiktik} />

                                </div>

        </div>

        <div className='sixth_div' style={{ width: '90%', margin: 'auto', paddingTop: '90px', paddingBottom: '90px' }}>

<div className='dblock' style={{ display: 'flex', gap: '97px' }}>
  <div >


    <div>
      <img className='vetaimimg specialimg' style={{
        width: '554px',
        // height: '800px'


      }} src={PngIcons.assetC} />
    </div>
  </div>

  <div className='nomt' style={{marginTop:'167px'}} >
    <div className='aim'>
    Blacksalt Books

    </div>

    <div className='aimtext'>
    Stop wasting hours tangled in receipts.

      <div className='aimpara'>
      Save on accounting fees, simplify your accounts and stress less over your BAS with Blacksalt Books. Our experienced bookkeeping specialists take the hassle out of running your business, whether you’re a small business, tradie or sole trader.


      </div>

      

  
  





 
  
    </div>
  </div>

  <div >
    <img className='newaimimg' style={{

      //width: '554px',
      //height:'705px'

    }} src={PngIcons.assetC} />
  </div>




</div>
</div>

        <div className='second_div' style={{ width: '100%', margin: 'auto', paddingTop: '163px', paddingBottom: '97px' ,background:'#FFFAF7',marginTop:'86px'}}>
          <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div className='box'>
                <img className='myimg' style={{
                  width: '48px',
                  height: '48px'
                }} src={PngIcons.assetB} />

              </div>

              <div className='boxtext'>
              No Hidden Costs
                <div className='boxpara'>
                Our fixed price agreement means no nasty surprise bills, ever
                </div>
              </div>
            </div>
            <div>
              <div className='box'>
                <img className='myimg' style={{
                  width: '48px',
                  height: '48px'
                }} src={PngIcons.assetd} />
              </div>

              <div className='boxtext'>
              Certified Xero Experts
                <div className='boxpara'>
                We have the knowledge and skills to get the most out of Xero's features
                </div>
              </div>
            </div>

            <div>
              <div className='box'>
                <img className='myimg' style={{
                  width: '48px',
                  height: '48px'
                }} src={PngIcons.assetA} />

              </div>

              <div className='boxtext'>
              Money-back Guarantee
                <div className='boxpara'>
                Money-back guarantee if you’re not satisfied within 3 months
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='third_div' style={{ width: '90%', margin: 'auto', paddingTop: '60px', paddingBottom: '61px' }}>

          <div className='dblock' style={{ display: 'flex', gap: '96px' }}>
         
         


            <div className='mt50' style={{ marginTop: '167px' }}>
              <div className='aim'>
              BOOKKEPING

              </div>

              <div className='aimtext'>
              Experienced bookkeepers.








                <div className='aimpara'>
                Tailor-made for small businesses and tradies, our fixed-price bookkeeping service gives you the confidence to grow your business, while we take care of the books. From on-time BAS lodgement to monthly bank reconciliations, our experienced bookkeepers work with you and your accountant. Saving you time, money and stress.
                </div>

             
            
                {/* <div className='aimbtn'>
                Tax Services
                </div> */}
              </div>
            </div>


            
            <div >
              <div >
                <img className='aimimg' style={{
                  width: '554px',
                  //height:'705px'

                }} src={PngIcons.newassetA} />
              </div>
            </div>




          </div>
        </div>

       

        <div className='fifth_div' style={{ width: '90%', margin: 'auto', paddingTop: '60px', paddingBottom: '61px' }}>

          <div className='dblock' style={{ display: 'flex', gap: '97px' }}>


          <div >
                <img className='verynewaimimg' style={{
                  width: '554px',
                  //height:'705px'

                }} src={PngIcons.newassetD} />
              </div>
            <div className='mt50' style={{marginTop:'167px'}}>
              <div className='aim'>
              REPORTING

              </div>

              <div className='aimtext'>
              Transparent reporting.
                <div className='aimpara'>
                Blacksalt Books makes regular reporting a cinch. Our quarterly consultations keep your business on track – from Profit and Loss reports to cash flow management, accounts receivable and bank account reconciliations.
                </div>

                {/* <div className='newaimbtn'>
                Business Structuring
                </div> */}
              </div>
            </div>
            <div >
            
            </div>





          </div>
        </div>

        <div className='sixth_div' style={{ width: '90%', margin: 'auto', paddingTop: '60px', paddingBottom: '60px' }}>

          <div className='dblock' style={{ display: 'flex', gap: '97px' }}>
         

            <div className='mt50'  style={{marginTop:'167px'}} >
              <div className='aim'>
              PAYROLL

              </div>

              <div className='aimtext'>
              Full-service employee payroll.

                <div className='aimpara'>
                We take care of payroll and super for your team, so you’re always up to date and compliant with your obligations. Blacksalt Books are experienced in payroll processing, including PAYG withholdings, entitlements, leave processing, superannuation, workers compensation and employment forms.
                </div>

            
            





{/*            
                <div className='thenewaimbtn'>

                ASIC Services
                </div> */}
              </div>
            </div>
            <div >


<div>
  <img className='vetaimimg specialimg' style={{
    width: '554px',
    // height: '800px'


  }} src={PngIcons.newassetC} />
</div>
</div>

            <div >
              <img className='newaimimg' style={{

                //width: '554px',
                //height:'705px'

              }} src={PngIcons.newassetC} />
            </div>




          </div>
        </div>

        <div className='fifth_div' style={{ width: '90%', margin: 'auto', paddingTop: '60px', paddingBottom: '61px' }}>

<div className='dblock' style={{ display: 'flex', gap: '97px' }}>


<div >
    <div >
      <img className='verynewaimimg' style={{
        width: '554px',
        //height:'705px'

      }} src={PngIcons.newassetB} />
    </div>
  </div>
  <div className='mt50'  style={{marginTop:'167px'}}>
    <div className='aim'>
    TRAINING

    </div>

    <div className='aimtext'>
    Tailored staff training..

      <div className='aimpara'>
      Want to upskill your team or learn more about our own financials? Our expert team offers tailored training to suit your needs on Xero, BAS preparation and day-to-day bookkeeping.
      </div>

  
    </div>
  </div>
  





</div>
</div>




        <div className='fourth_div' style={{ width: '90%', margin: 'auto', paddingTop: '76px', paddingBottom: '76px' }}>


<div className='maintaindiv'>
  <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div style={{ width: '30%' }} className='nowidth' >
      <img className='noimg nbbimgae' style={{ width: '375px' }} src={PngIcons.dotpng}></img>
    </div>

    <div className='nowidth' style={{ width: '53%', backgroundImage: `url(${PngIcons.pattern})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '1296px' }}>
      <div className='filterheading nowidth'>
      Contact us today to arrange a consultation
       

        <div className='getbtn'>Get In Touch</div>
      </div>
    </div>
  </div>
</div>
</div>



<div className='newthird_div' style={{ width: '90%', margin: 'auto', paddingTop: '64px', paddingBottom: '64px' }}>


<div className='clienttext'>
    Our Clients Speak

    <div className='clientpara'>
        We have been working with clients around the world
    </div>
</div>


<Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Transforming Finances, Saving Time and Money
                        <div className='clientboxpara'>
                        Black Salt transformed our financial management. Their accounting expertise has saved us both time and money. Highly recommended!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Sarah J.
                        <div className='cooperpara'>
                        Small Business Owner
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    SMSF Made Simple and Stress-Free
                        <div className='clientboxpara'>
Managing my SMSF was daunting until I found Black Salt. Their team made it simple and stress-free. I couldn't be happier with the service.
                            
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Michael R.
                        <div className='cooperpara'>
                        Retiree
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Beyond Accounting: Partners in Growth
                        <div className='clientboxpara'>
As a growing startup, we needed precise financial guidance. Black Salt delivered beyond our expectations. They're not just accountants, they're growth partners. 
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Emily T. 
                        <div className='cooperpara'>
                        Tech Entrepreneur
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Maximizing Retirement Savings with Expert SMSF Advice
                        <div className='clientboxpara'>
Black Salt's SMSF advice has been invaluable. They've helped maximize my retirement savings while ensuring full compliance. True professionals!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    David L.
                        <div className='cooperpara'>
                        Self-Managed Investor
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Proactive Approach, Unmatched Attention to Detail
                        <div className='clientboxpara'>
Switching to Black Salt was the best decision for our company's finances. Their proactive approach and attention to detail are unmatched.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Lisa M.
                        <div className='cooperpara'>
                        CFO
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Navigating SMSF Complexities with Confidence
                        <div className='clientboxpara'>
I was lost in the complexities of SMSF regulations. Black Salt provided clear, expert guidance. Now I feel confident about my financial future.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Robert K.
                        <div className='cooperpara'>
                        SMSF Trustee
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel>
{/* <Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            The Nexus Financial Advisors  team have given us honest and open advice. Their fees are reasonable and they have a great team who are always ready to assist. I am really looking forward to working with them
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Drew W
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team at  Nexus Financial Advisors are very professional. They contacted me promptly and were very informative and thorough. Would recommend them in the future if you’re wanting help with your superannuation or finances
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Amanda Crouch
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Very supportive, informative and helpful. Let’s see if the rollover is worth it.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Charles Nielsen
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            Really supportive and easy process to complete
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Jessica Tones
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team has always been very helpful and transparent in what they do. I have had multiple interactions and have always been happy with the outcome.
                            The personal touch makes you feel like you're not another number, compared to other places. Highly recommended to give them a call and find out what they can do for you.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        michael Ross
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Second to none. The level of professionalism and efficiency will make me keep working with Nexus.
                            Brendan was fantastic and went out of his way to speak with me and assist.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Cam Ingles
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel> */}


</div>
    


        <div className='footer-div'>

          <MDBFooter className='text-center text-lg-start text-muted'>
            <section className>


            </section>

            <section className=''>
              <MDBContainer >

                <MDBRow  >
                  <div className='dblock' style={{ display: 'flex', gap: '176px' }}>
                    <div style={{ width: '50%' }}>
                      <MDBCol >
                        <h6 className=' mb-4'>
                          <MDBIcon />
                          <img style={{
                            width: '227px',

                          }} src={PngIcons.footerlogo} />
                        </h6>
                        {/* <p className='footerpara'>
                        Future Financial Wealth T/As  Nexus Financial Advisors 
Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)
                        </p> */}
                        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.facebook} />
                          </div>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.twitter} />
                          </div>

                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.likedin} />
                          </div>
                        </div>
                      </MDBCol>
                    </div>
                    <div className='dblock textleft newgap' style={{ display: 'flex', gap: '190px' }}>

                      <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' >
                        <h6 className='footertext'>Sitemap</h6>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Home
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Services
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Assessment
                        </p>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />Reviews
                        </p>



                      </MDBCol>

                      <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                        <h6 className=' footertext'>Info</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          About Us
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Blogs
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Contact
                        </p>





                      </MDBCol>

                      <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                        <h6 className='footertext'>Security</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          FAQ
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Terms & Conditions
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Privacy Policy
                        </p>

                      </MDBCol>
                    </div>
                  </div>

                  {/* <div className='newfooterpara'>
                    <div>ABN: 86 663 229 847

                      <div>ACN: 663 229 847</div>
                    </div>

                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>

                    <a
        href={financialServicesGuidePdfPath}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'blue' }}
      >
        Financial Services Guide
      </a>
                    </div>
                    <p>Future Financial Wealth T/As  Nexus Financial Advisors </p>
                    


                    <p>Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)</p>


                  </div> */}
                </MDBRow>

              </MDBContainer>
            </section>


          </MDBFooter>

        </div>
            </div>
        </div>
    )
}


export default Assement;
import React, { useState,useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';
import PngIcons from './icons/PngIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import Carousel from 'react-bootstrap/Carousel';
import { Formik, useFormik } from 'formik';
import CustomDropDown from './CustomDropDown';
import axios from 'axios';
import { toast } from 'react-toastify';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


import { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const Contact = () => {

    const formRef = useRef(null);

    const [loader, setLoader] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
          Areaofinterest: "",
            hearaboutus: "",
            howcanwehelp: "",
        },


    });
    useEffect(() => {


    }, [formik.values])


    const [open, setOpen] = React.useState(false);


    const handleSubmit = async () => {

        setLoader(true)

        const data = {
            name: formik.values.name,
            email: formik.values.email,
            phone: formik.values.phone,
            tags: ["contact form"],
            customField: {
                "ndN9MObjvHmFzaHwwIJK": formik.values.Areaofinterest,
                "OFT4X4jxy4H9LIwtmdNc": formik.values.hearaboutus,
                "IhnYrhPSI0oyBbznOgGT": formik.values.howcanwehelp,
                

            },
        };


        
        var config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://rest.gohighlevel.com/v1/contacts/',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Ilp5ZFIwb0xXekY2VktROXZPWEdMIiwiY29tcGFueV9pZCI6ImxhUWpybGF4eEdTdXdmY2cyYjVsIiwidmVyc2lvbiI6MSwiaWF0IjoxNzA3MDg4Nzc0OTcyLCJzdWIiOiJ1c2VyX2lkIn0.BLIqrSTcynWl0EfXFUCzGs0OjP95iASGlYrmpHmlyOc'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                // toast.success('Form submitted successfully', {
                    setOpen(true);
                toast.success('Thank you contacting us - a team member will be in touch soon', {
                    position: 'top-center'
                  });
                setLoader(false)
                formik.resetForm();
                // window.location.reload();
            })
            .catch(function (error) {
                toast.error('Error: Problem in uploading the data', {
                    position: 'top-center'
                  });
                setLoader(false)
                console.log(error);
            });

        

    }



    const handleSubmits = async(event) => {
        event.preventDefault();
        setLoader(true);
        const formElement = formRef.current;
    
        html2canvas(formElement).then((canvas) => {
            canvas.toBlob((blob) => {
                const file = new File([blob], "form-output.pdf", { type: "application/pdf" });
                const formData = new FormData();
                formData.append("file", file);
                console.log('file',file);
    
            }, 'application/pdf');
    
            setLoader(false);
        }).catch((error) => {
            toast.error('Error generating PDF: ' + error.message);
            setLoader(false);
        });
    };
    
    // const handleSubmit = async(event)=>{
    //     event.preventDefault();
    //     setLoader(true);
    //     const formElement = formRef.current;
    //     console.log(formElement);
    //     html2canvas(formElement).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF({
    //             orientation: 'portrait',
    //             unit: 'pt',
    //             format: 'a4'
    //     });
    //         console.log('a1')
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
    //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    //         pdf.save('form-output.pdf');
    //         toast.success('PDF generated successfully!');
    //         setLoader(false);
    //     }).catch((error) => {
    //         toast.error('Error generating PDF: ' + error.message);
    //         setLoader(false);
    //     });
        
    // }

    const financialServicesGuidePdfPath = '/ANIG-FSG-Version-3-03.03.23-Nexus.pdf';


    const handleClose = () => {
        setOpen(false);
    };


    return (
        <form ref={formRef} onSubmit={handleSubmit} className='w-100' id='contactcontainer' >

<Navbar expand="lg" className="bg-body-tertiary">
        <Container className='navbarmain' fluid >

          <Navbar.Brand href="#"><img className='logoimg' style={{
            width: '167px',
            //height: '40px'
          }} src={PngIcons.logo} /></Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 gap"
              style={{ maxHeight: '100px', marginLeft: 'auto', gap: '58px' }}
              navbarScroll
            >
              <Nav.Link  className='nav' href="/">Home

              </Nav.Link>

              <Nav.Link  className='nav' href="/about">About Us

              </Nav.Link>

              <Nav.Link  className='nav' href="/assesment">Advisory

              </Nav.Link>
     

              <Nav.Link  className='nav' href="/service">Books

              </Nav.Link>
        

            </Nav>
            <Form >
         

              <Button   className='signin'  >Contact Us</Button>
              {/* <div className='signin'> Free Assessment</div> */}

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

            <div>

                <div className='first_div' style={{ width: '100%', marginTop: '-8px' }}>

                    <div className='dblock ppad' style={{ display: 'flex', justifyContent: 'space-between', padding: '120px' }}>

                        <div>

                            <div className='contact'>
                                Contact
                            </div>
                            <div className='contacttext'>Get in touch with us</div>
                            <div style={{ display: 'flex', gap: '10px', paddingTop: '30px' }}>
                                <div>
                                    <img style={{ width: '32px', height: '32px' }} src={PngIcons.message} />
                                </div>
                                <div className='send'>
                                    Send us an email

                                    <div className='info'>
                                    hello@blacksaltgroup.com
                                    </div>
                                </div>

                            </div>

                            <div style={{ display: 'flex', gap: '10px', paddingTop: '26px' }}>
                                <div>
                                    <img style={{ width: '32px', height: '32px' }} src={PngIcons.phone} />
                                </div>
                                <div className='send'>
                                    Give us a call

                                    <div className='info'>
                                    1800 274 042
                                    </div>
                                </div>

                            </div>


                            <div style={{ display: 'flex', gap: '10px', paddingTop: '26px' }}>
                                <div>
                                    <img style={{ width: '32px', height: '32px' }} src={PngIcons.location} />
                                </div>
                                <div className='send'>
                                    Visit us in person

                                    <div className='info'>
                                    15/2 Grevillea Street, 
                                    Tanah Merah QLD 4128
                                    </div>
                                </div>

                            </div>

                            {/* <div className='follow'>
                                Follow us
                                <div>
                                    <img className='m60' style={{ width: '160px' }} src={PngIcons.social} />
                                </div>
                            </div> */}


                        </div>



                        <div className='nowidth' style={{ width: '43%' }}>
                            <div className='nowidth nopad'  >
                                <div style={{ width: '80%', margin: 'auto' }} className='inquire width-95' >
                                    <div style={{ marginBottom: '10px' }} className='contact'>
                                        Contact
                                    </div>
                                    <div className='inquiretext'>Free Online Review</div>
                                    <div>
                                        <div className='specialinputtext'>Name*</div>
                                        <div className='inputtext'>

                                            <input value={formik.values.name}
                                            onChange={(e) => formik.setValues({ ...formik.values, name: e.target.value })} style={{ width: '100%', height: '52px', border: 'none', background: 'transparent' }} type="text" />
                                        </div>
                                    </div>
                                    <div className='dblock' style={{ display: 'flex', gap: '10px' }}>
                                        <div className='nowidth' style={{ width: '50%' }}>
                                            <div style={{ marginTop: '10px' }} className='specialinputtext'>  Email*</div>
                                            <div className='inputtext'>

                                                <input value={formik.values.email}
                                            onChange={(e) => formik.setValues({ ...formik.values, email: e.target.value })} style={{ width: '100%', height: '52px', border: 'none', background: 'transparent' }} type="text" />
                                            </div>
                                        </div>


                                        <div className='nowidth' style={{ width: '50%' }}>
                                            <div style={{ marginTop: '10px' }} className='specialinputtext'>   Phone*</div>
                                            <div className='inputtext'>

                                                <input value={formik.values.phone}
                                            onChange={(e) => formik.setValues({ ...formik.values, phone: e.target.value })} style={{ width: '100%', height: '52px', border: 'none', background: 'transparent' }} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dblock' style={{ display: 'flex', gap: '10px' }}>
                                        <div className='nowidth' style={{ width: '50%' }}>
                                            <div style={{ marginTop: '10px' }} className='specialinputtext'>  Area of Interest*</div>
                                            <div >

                                                {/* <input style={{ width: '100%', height: '52px', border: 'none', background: 'transparent' }} type="text" /> */}
                                                <CustomDropDown
                                                          
                                                            onChange={(e) => formik.setValues({ ...formik.values, Areaofinterest: e.target.value })}
                                                            //className='drd'
                                                            className='inputtext'

                                                        
                                                            value={
                                                                [
                                                                    {
                                                                        title: 'Advisory/Business Structure(initial consultant required)',
                                                                        value: 'Advisory/Business Structure(initial consultant required)'

                                                                    },
                                                                    {
                                                                        title: 'Tax/Bookkeeping (no initial consultant required)',
                                                                        value: 'Tax/Bookkeeping (no initial consultant required)'

                                                                    },
                                                                    {
                                                                        title: 'SMSF Establishment & ongoing Administration',
                                                                        value: 'SMSF Establishment & ongoing Administration'


                                                                    },
                                                                    // {
                                                                    //     title: 'Education',
                                                                    //     value: 'Education'


                                                                    // },
                                                                    {
                                                                        title: 'Other',
                                                                        value: 'Other'


                                                                    },
                                                                    
                                                                   
                                                                  

                                                                ]
                                                            }
                                                            //icon={<SvgIcons.ModalFilter />}

                                                        />
                                            </div>
                                        </div>


                                        <div className='nowidth' style={{ width: '50%' }}>
                                            <div style={{ marginTop: '10px' }} className='specialinputtext'>    How did you hear about us?*</div>
                                            <div className='inputtext'>

                                                <input value={formik.values.hearaboutus}
                                            onChange={(e) => formik.setValues({ ...formik.values, hearaboutus: e.target.value })} style={{ width: '100%', height: '52px', border: 'none', background: 'transparent' }} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ marginTop: '10px' }} className='specialinputtext'>  How can we help?*</div>
                                        <div className='inputtext'>

                                            <input value={formik.values.howcanwehelp}
                                            onChange={(e) => formik.setValues({ ...formik.values, howcanwehelp: e.target.value })} style={{ width: '100%', height: '180px', border: 'none', background: 'transparent' }} type="text" />
                                        </div>
                                    </div>
                                  

                                    <div style={{cursor:'pointer'}} onClick={handleSubmit} className='startbtn'>
                                        {loader ? <div className="d-flex align-items-center gap-8"><img src={'loading2.gif'} style={{marginRight: '8px'}} width={20} height={20} />{'Submit'}</div>  : 'Submit'}
                                        </div>


                                </div>
                            </div>

                        </div>

                    </div>



                    {/* <div>
                                <img className='tiktik' src={PngIcons.tiktik} />

                                </div> */}

                </div>


                <div className='newthird_div' style={{ width: '90%', margin: 'auto', paddingTop: '64px', paddingBottom: '64px' }}>


<div className='clienttext'>
    Our Clients Speak

    <div className='clientpara'>
        We have been working with clients around the world
    </div>
</div>


<Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Transforming Finances, Saving Time and Money
                        <div className='clientboxpara'>
                        Black Salt transformed our financial management. Their accounting expertise has saved us both time and money. Highly recommended!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Sarah J.
                        <div className='cooperpara'>
                        Small Business Owner
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    SMSF Made Simple and Stress-Free
                        <div className='clientboxpara'>
Managing my SMSF was daunting until I found Black Salt. Their team made it simple and stress-free. I couldn't be happier with the service.
                            
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Michael R.
                        <div className='cooperpara'>
                        Retiree
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Beyond Accounting: Partners in Growth
                        <div className='clientboxpara'>
As a growing startup, we needed precise financial guidance. Black Salt delivered beyond our expectations. They're not just accountants, they're growth partners. 
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Emily T. 
                        <div className='cooperpara'>
                        Tech Entrepreneur
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Maximizing Retirement Savings with Expert SMSF Advice
                        <div className='clientboxpara'>
Black Salt's SMSF advice has been invaluable. They've helped maximize my retirement savings while ensuring full compliance. True professionals!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    David L.
                        <div className='cooperpara'>
                        Self-Managed Investor
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Proactive Approach, Unmatched Attention to Detail
                        <div className='clientboxpara'>
Switching to Black Salt was the best decision for our company's finances. Their proactive approach and attention to detail are unmatched.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Lisa M.
                        <div className='cooperpara'>
                        CFO
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Navigating SMSF Complexities with Confidence
                        <div className='clientboxpara'>
I was lost in the complexities of SMSF regulations. Black Salt provided clear, expert guidance. Now I feel confident about my financial future.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Robert K.
                        <div className='cooperpara'>
                        SMSF Trustee
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel>
{/* <Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            The Nexus Financial Advisors  team have given us honest and open advice. Their fees are reasonable and they have a great team who are always ready to assist. I am really looking forward to working with them
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Drew W
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team at  Nexus Financial Advisors are very professional. They contacted me promptly and were very informative and thorough. Would recommend them in the future if you’re wanting help with your superannuation or finances
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Amanda Crouch
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Very supportive, informative and helpful. Let’s see if the rollover is worth it.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Charles Nielsen
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            Really supportive and easy process to complete
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Jessica Tones
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team has always been very helpful and transparent in what they do. I have had multiple interactions and have always been happy with the outcome.
                            The personal touch makes you feel like you're not another number, compared to other places. Highly recommended to give them a call and find out what they can do for you.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        michael Ross
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Second to none. The level of professionalism and efficiency will make me keep working with Nexus.
                            Brendan was fantastic and went out of his way to speak with me and assist.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Cam Ingles
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel> */}


</div>
    


        <div className='footer-div'>

          <MDBFooter className='text-center text-lg-start text-muted'>
            <section className>


            </section>

            <section className=''>
              <MDBContainer >

                <MDBRow  >
                  <div className='dblock' style={{ display: 'flex', gap: '176px' }}>
                    <div style={{ width: '50%' }}>
                      <MDBCol >
                        <h6 className=' mb-4'>
                          <MDBIcon />
                          <img style={{
                            width: '227px',

                          }} src={PngIcons.footerlogo} />
                        </h6>
                        {/* <p className='footerpara'>
                        Future Financial Wealth T/As  Nexus Financial Advisors 
Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)
                        </p> */}
                        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.facebook} />
                          </div>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.twitter} />
                          </div>

                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.likedin} />
                          </div>
                        </div>
                      </MDBCol>
                    </div>
                    <div className='dblock textleft newgap' style={{ display: 'flex', gap: '190px' }}>

                      <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' >
                        <h6 className='footertext'>Sitemap</h6>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Home
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Services
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Assessment
                        </p>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />Reviews
                        </p>



                      </MDBCol>

                      <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                        <h6 className=' footertext'>Info</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          About Us
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Blogs
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Contact
                        </p>





                      </MDBCol>

                      <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                        <h6 className='footertext'>Security</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          FAQ
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Terms & Conditions
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Privacy Policy
                        </p>

                      </MDBCol>
                    </div>
                  </div>

                  {/* <div className='newfooterpara'>
                    <div>ABN: 86 663 229 847

                      <div>ACN: 663 229 847</div>
                    </div>

                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>

                    <a
        href={financialServicesGuidePdfPath}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'blue' }}
      >
        Financial Services Guide
      </a>
                    </div>
                    <p>Future Financial Wealth T/As  Nexus Financial Advisors </p>
                    


                    <p>Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)</p>


                  </div> */}
                </MDBRow>

              </MDBContainer>
            </section>


          </MDBFooter>

        </div>
            </div>


            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
                <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                >
                Thank you contacting us - a team member will be in touch soon
                </Alert>
            </Snackbar>
        </form>
    )
}


export default Contact;
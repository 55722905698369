// import { useState, useRef,useEffect } from "react";
// import PngIcons from "./icons/PngIcons";

// const Draganddrop = () => {
//   const [files, setFiles] = useState(null);
//   const inputRef = useRef();


// //   useEffect(() => {

// //     if (file) {
// //       setFiles([file]);
// //     }
// //   }, [file]);

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setFiles(event.dataTransfer.files)
//   };
  
//   const handleUpload = () => {
//     const formData = new FormData();
//     formData.append("Files", files);
//     console.log(formData.getAll())

   
//   };

//   if (files) return (
//     <div className="uploads">
//         <ul>
//             {Array.from(files).map((file, idx) => <li key={idx}>{file.name}</li> )}
//         </ul>
      
//     </div>
//   )

//   return (
//     <>
//         <div 
//             className="dropzone"
//             onDragOver={handleDragOver}
//             onDrop={handleDrop}
//         >
//             <div onClick={() => inputRef.current.click()} style={{marginBottom:'18px',cursor:'pointer',marginTop:'22px'}}>
//             <img  style={{
//             width: '39px',
//             height: '28px'
//           }} src={PngIcons.mask} />
//             </div>
//           <div onClick={() => inputRef.current.click()} className="drag">Browse Files</div>
//           <div onClick={() => inputRef.current.click()} className="dragpara">Drag and drop files here</div>
//           <input 
//             type="file"
//             multiple
//             onChange={(event) => setFiles(event.target.files)}
//             hidden
//             accept="image/png, image/jpeg"
//             ref={inputRef}
//           />
        
//         </div>
//     </>
//   );
// };

// export default Draganddrop;




// import React, { useState, useRef } from "react";
// import PngIcons from "./icons/PngIcons";

// const Draganddrop = ({
//   type,
//   multiple,
//   onChange,
//   hidden,
//   accept,
//   inputRef
// }) => {
//   const [files, setFiles] = useState(null);

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     setFiles(event.dataTransfer.files);
//   };
  
//   const handleUpload = () => {
//     const formData = new FormData();
//     formData.append("Files", files);
//     console.log(formData.getAll());
//   };

//   if (files) return (
//     <div className="uploads">
//       <ul>
//         {Array.from(files).map((file, idx) => (
//           <li key={idx}>{file.name}</li>
//         ))}
//       </ul>
//     </div>
//   );

//   return (
//     <>
//       <div 
//         className="dropzone"
//         onDragOver={handleDragOver}
//         onDrop={handleDrop}
//       >
//         <div onClick={() => inputRef.current.click()} style={{marginBottom:'18px',cursor:'pointer',marginTop:'22px'}}>
//           <img  style={{
//             width: '39px',
//             height: '28px'
//           }} src={PngIcons.mask} />
//         </div>
//         <div onClick={() => inputRef.current.click()} className="drag">Browse Files</div>
//         <div onClick={() => inputRef.current.click()} className="dragpara">Drag and drop files here</div>
//         <input 
//           type={type}
//           multiple={multiple}
//           onChange={onChange}
//           hidden={hidden}
//           accept={accept}
//           ref={inputRef}
//         />
//       </div>
//     </>
//   );
// };

// export default Draganddrop;








// import React, { useState, useRef } from "react";
// import PngIcons from "./icons/PngIcons";
// import AWS from "aws-sdk";
// import ReactS3 from "react-s3";
// import S3FileUpload from 'react-s3';
// import { Buffer } from 'buffer'
 
// //Optional Import
// import { uploadFile } from 'react-s3';







// const s3 = new AWS.S3();


// const Draganddrop = ({
//   type,
//   multiple,
//   onChange,
//   hidden,
//   accept,
//   inputRef
// }) => {
//   const [files, setFiles] = useState(null);

//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const droppedFiles = event.dataTransfer.files;
//     setFiles(droppedFiles);
//     onChange(droppedFiles); 
//     handleUpload(droppedFiles[0]); 
//   };




//   const handleUpload = async(file) => {
//     if (!file) return;
  
//     const params = {
//       Bucket: 'live-walletly',
//        Key: file.name,
//       Body: file,
//       ACL: 'public-read' 
//     };


//     console.log('params',params);

   

//     return await new Promise(resolve => {
//     s3.upload(params, (err, data) => {

//         console.log("data",data);

//       if (err) {
//         console.error("Error uploading file to S3:", err);
//       } else {
//         console.log("File uploaded successfully to S3:", data.Location);
        
//       }
//     });
    
//  })
// // //     var upload = s3

// // //       .putObject(params)


// // //       .on("httpUploadProgress", (evt) => {
     
// // //         console.log('upload',upload);

// // //         // File uploading progress
// // //         console.log(
// // //           "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
// // //         );
// // //       })
// // //       .promise();

// // //     await upload.then((err, data) => {
// // //       console.log(err);
// // //       // Fille successfully uploaded
// // //       alert("File uploaded successfully.");
// // //     });

  
//    };






// //   const params = {
// //     Bucket: env.AWS_BUCKET,
// //     // ContentType : file.mimetype,
// //     ContentType: 'video/webm',
// //     Key: key,
// //     Body: file,
// //     ACL: 'public-read'
// // }

// // return await new Promise(resolve => {
// //     s3.upload(params, (err, data) => {
// //         console.log('data', data);
// //         if (err) resolve({
// //             success: false,
// //             message: Responses.UploadImage.ERROR,
// //             error: err
// //         })
// //         if (data) resolve({
// //             success: true,
// //             message: Responses.UploadImage.UPLOADED,
// //             data: data.Location
// //         })
// //     })
// //     console.log('5',new Date().valueOf())

// //     fs.unlinkSync(inputPath);
// //     fs.unlinkSync(outputPath);
// // })




// // const handleUpload = async (file) => {
// //     if (!file) return;

// //     const params = {
// //         Bucket: 'dev-walletly',
// //         Key: file.name,
// //         Body: file,
// //         ACL: 'public-read'
// //     };

// //     console.log('params', params);

// //     return new Promise((resolve, reject) => {
// //         const uploader = s3.upload(params);

// //         uploader.on('httpUploadProgress', (evt) => {
// //             console.log('Uploading progress:', parseInt((evt.loaded * 100) / evt.total) + '%');
// //         });

// //         uploader.send((err, data) => {
// //             if (err) {
// //                 console.error('Error uploading file to S3:', err);
// //                 reject(err);
// //             } else {
// //                 console.log('File uploaded successfully to S3:', data.Location);
// //                 resolve(data);
// //             }
// //         });
// //     });
// // };



  
//   const handleChange = (event) => {
//     if (!event || !event.target) {
//       console.error("Invalid event object:", event);
//       return;
//     }
  
//     const selectedFile = event.target.files && event.target.files[0];
//     if (!selectedFile) {
//       console.error("No file selected.");
//       return;
//     }
  
//     handleUpload(selectedFile); 
//   };
  
  
  
  
//   if (files) return (
//     <div className="uploads">
//       <ul>
//         <li>{files.name}</li>
//       </ul>
//     </div>
//   );

//   return (
//     <>
//       <div 
//         className="dropzone"
//         onDragOver={handleDragOver}
//         onDrop={handleDrop}
//       >
//         <div onClick={() => inputRef.current.click()} style={{marginBottom:'18px',cursor:'pointer',marginTop:'22px'}}>
//           <img  style={{
//             width: '39px',
//             height: '28px'
//           }} src={PngIcons.mask} />
//         </div>
//         <div onClick={() => inputRef.current.click()} className="drag">Browse Files</div>
//         <div onClick={() => inputRef.current.click()} className="dragpara">Drag and drop files here</div>
//         <input 
//           type={type}
//           multiple={multiple}
//           onChange={handleChange}
//           hidden={hidden}
//           accept={accept}
//           ref={inputRef}
//         />
//       </div>
//     </>
//   );
// };

// export default Draganddrop;







// import React, { useState, useRef } from "react";
// import PngIcons from "./icons/PngIcons";
// import axios from "axios";

// const Draganddrop = ({
//   type,
//   multiple,
//   hidden,
//   accept,
//   inputRef
// }) => {
//   const [files, setFiles] = useState(null);




//   const handleDragOver = (event) => {
//     event.preventDefault();
//   };

//   const handleDrop = (event) => {
//     event.preventDefault();
//     const droppedFiles = event.dataTransfer.files;
//     setFiles(droppedFiles);
//     handleUpload(droppedFiles[0]);
//   };

//   const handleUpload = async (file) => {
//     console.log('file',file);
//     const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/"+file.lastModified;
//     const formData = new FormData();
//     formData.append('image', file, file.name)
//     const UploadImage = await axios.post(url, formData)

//     console.log('UploadImage',UploadImage.data.data)

//     const options = {
//       method: "POST",
//       body: formData
//     };


//   };

//   const handleChange = (event) => {
//     const selectedFile = event.target.files && event.target.files[0];
//     if (!selectedFile) {
//       console.error("No file selected.");
//       return;
//     }
//     handleUpload(selectedFile);
//   };

//   if (files) {
//     return (
//       <div className="uploads">
//         <ul>
//           <li>{files[0].name}</li> 
//         </ul>
//       </div>
//     );
//   }

//   return (
//     <>
//       <div 
//         className="dropzone"
//         onDragOver={handleDragOver}
//         onDrop={handleDrop}
//       >
//         <div onClick={() => inputRef.current.click()} style={{marginBottom:'18px',cursor:'pointer',marginTop:'22px'}}>
//           <img  style={{
//             width: '39px',
//             height: '28px'
//           }} src={PngIcons.mask} />
//         </div>
//         <div onClick={() => inputRef.current.click()} className="drag">Browse Files</div>
//         <div onClick={() => inputRef.current.click()} className="dragpara">Drag and drop files here</div>
//         <input 
//           type={type}
//           multiple={multiple}
//           onChange={handleChange}
//           hidden={hidden}
//           accept={accept}
//           ref={inputRef}
//         />
//       </div>
//     </>
//   );
// };

// export default Draganddrop;





import React, { useState, useRef } from "react";
import PngIcons from "./icons/PngIcons";
import axios from "axios";
import { toast } from "react-toastify";

const Draganddrop = ({
  type,
  multiple,
  hidden,
  accept,
  inputRef,
  formik // Accept formik as a prop
}) => {
  const [files, setFiles] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    console.log('droppedFiles',droppedFiles);
    setFiles(droppedFiles);
    handleUpload(droppedFiles);
  };

  // const handleUpload = async (file) => {
  //   console.log('file', file);
  //   const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + file.lastModified;
  //   const formData = new FormData();
  //   formData.append('image', file, file.name)
  //   const UploadImage = await axios.post(url, formData)
  //   console.log('UploadImage', UploadImage.data.data)

  //   // Set the file value using formik
  //   formik.setFieldValue('file', UploadImage.data.data);
  // };

  const handleUpload = async (file) => {
    console.log('file', typeof file);
    console.log('file', file[0]);
    console.log('file', file[1]);
    console.log('file', file.length);

    let temp = []
    
    try {
      
      
      // 
      if(file.length>0){
        for (const iterator of file) {
          console.log(iterator);
          // const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + str.replace(/\s/g, "")iterator.lastModified;
          // const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + iterator.name.replace(/\s/g, "");
          const url = "https://api.walletly.ai/api/v4/utils/upload/image/public?desiredPath=blacksalt/" + iterator.name.replace(/\s/g, "");
          const formData = new FormData();
          formData.append('image', iterator, iterator.name);
          const response = await axios.post(url, formData);
          console.log('UploadImage', response);
          console.log('UploadImage', response.data.data);
          console.log('UploadImage', typeof response.data.data);
          temp.push(response.data.data)


          if (formik.values.file && formik.values.second_person_file) {
        
            formik.setFieldValue('current_superannuation_fund_file', response.data.data);
          } else {
           
            if (!formik.values.file) {
              formik.setFieldValue('file', response.data.data);
            } else if (!formik.values.second_person_file) {
              formik.setFieldValue('second_person_file', response.data.data);
            }
          }
        }
      //   file.forEach(async function(singleFile, index) {
      //     console.log(`File ${index + 1}:`, singleFile);          
          
      // });
      }
      var config1 = {
        method: 'get',
      maxBodyLength: Infinity,
        url: 'https://rest.gohighlevel.com/v1/contacts/lookup?email='+formik.values.email,
        headers: { 
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Ilp5ZFIwb0xXekY2VktROXZPWEdMIiwiY29tcGFueV9pZCI6ImxhUWpybGF4eEdTdXdmY2cyYjVsIiwidmVyc2lvbiI6MSwiaWF0IjoxNzA3MDg4Nzc0OTcyLCJzdWIiOiJ1c2VyX2lkIn0.BLIqrSTcynWl0EfXFUCzGs0OjP95iASGlYrmpHmlyOc'
        }
      };

      axios(config1)
      .then(async function (response) {
        console.log('JSON.stringify(response.data)',response.data.contacts[0].id);
          console.log('formik', formik.values);
          console.log('temp', temp);

          for (const value of temp) {
            
            const data = {
              "customField": {
                "6BUUxl9HgDMfen79ktnS": value.toString() //SMSF
                // "vZlXRnv8IGhNe0kd5Nw2": value.toString() //Advisory
              },
            };
            // const data1 = {
            //   "customField": {
            //     "6BUUxl9HgDMfen79ktnS": value.toString() //SMSF
            //     // "vZlXRnv8IGhNe0kd5Nw2": value.toString() //Advisory
            //   },
            // };
      
            // console.log('data', formik.values["SMSF"]===''?data:data1);
            console.log('data', data);
      
      
          var config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: 'https://rest.gohighlevel.com/v1/contacts/'+response.data.contacts[0].id,
            headers: {
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6Ilp5ZFIwb0xXekY2VktROXZPWEdMIiwiY29tcGFueV9pZCI6ImxhUWpybGF4eEdTdXdmY2cyYjVsIiwidmVyc2lvbiI6MSwiaWF0IjoxNzA3MDg4Nzc0OTcyLCJzdWIiOiJ1c2VyX2lkIn0.BLIqrSTcynWl0EfXFUCzGs0OjP95iASGlYrmpHmlyOc'
            },
            data: data
            // data: formik["SMSF"]===''?data:data1
          };
      
          axios(config)
            .then(function (response1) {
              console.log(response1.data);
            })
            .catch(function (error1) {
              console.log(error1);
            });
          }
        })
      .catch(function (error) {
        console.log('JSON.stringify(response.data) error',error);
      });

      


      toast.success('File uploaded successfully', {
        position: 'top-center'
      });

      // const response = await axios.post(url, formData);
      // console.log('UploadImage', response.data.data);
  

      
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };
  

  const handleChange = (event) => {
    const selectedFile = event.target.files;
    if (!selectedFile) {
      console.error("No file selected.");
      return;
    }
    handleUpload(selectedFile);
  };

  if (files) {
    return (
      <div className="uploads">
        <ul>
          <li>{files[0].name}</li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <div
        className="dropzone"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div onClick={() => inputRef.current.click()} style={{ marginBottom: '18px', cursor: 'pointer', marginTop: '22px' }}>
          <img style={{
            width: '39px',
            height: '28px'
          }} src={PngIcons.mask} />
        </div>
        <div onClick={() => inputRef.current.click()} className="drag">Browse Files</div>
        <div onClick={() => inputRef.current.click()} className="dragpara">Drag and drop files here</div>
        <input
          type={type}
          multiple={multiple}
          onChange={handleChange}
          hidden={hidden}
          accept={accept}
          ref={inputRef}
        />
      </div>
    </>
  );
};

export default Draganddrop;




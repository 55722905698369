// import * as React from 'react';
// import Box from '@mui/material/Box';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

// export default function CustomDropDown(props) {
//     const [age, setAge] = React.useState('');

//     const handleChange = (event) => {
//         setAge(event.target.value);
//         console.log('event.target.value', event.target.value);
//     };


//     return (
//         <Box sx={{

//             '& > :not(style)': { width: props?.width || '100%', fontFamily: props?.fontFamily || 'poppins' },
//         }}
//         >
//             <FormControl fullWidth>
//                 <InputLabel id="demo-simple-select-label" className={props?.className} > {props.placeholder || ''}</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={age}

//                     label={props.placeholder || ''}
//                     onChange={handleChange}
//                     IconComponent={React.Fragment}
//                     // className={props?.className}
//                     // style={{ boxShadow: props?.boxShadow ? props?.boxShadow : '' }}
//                     // style={{ back: props?.boxShadow ? props?.boxShadow : '' }}
//                 >

//                     {props?.value &&
//                         props?.value.map((item, index) => {
//                             console.log('items', props?.value);
//                             return <MenuItem key={index} value={item.value || item.title}>{item.title || item.value}</MenuItem>
//                         })
//                     }



//                 </Select>
//                 {props.icon && <div
//                     style={{ position: 'absolute', top: props.top ? props.top : '14px', right: props.right ? props.right : '20px', width: 'fit-content', }}>{props.icon}</div>}

//             </FormControl>
//         </Box>
//     );
// }


// import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { useEffect,useRef } from 'react';

// export default function CustomDropDown(props) {
//     //const [age, setAge] = React.useState('');

//    //const handleChange = (event) => {
//         //setAge(event.target.value);
//     //};
//     const selectRef = useRef(null);
//     const [selectedValue, setSelectedValue] = React.useState(props.defaultValue || 'English');

//     const handleValueChange = (event) => {
//         // props.onChange();
//         setSelectedValue(event.target.value,);
//     };


//     useEffect(() => {
//         setSelectedValue(props.defaultValue ?? props.value);
//       }, [props.defaultValue, props.value]);
      
//     //   const handleIconClick = () => {
//     //     console.log('selectRef', selectRef);
    
//     //     // Use getElementById to get a reference to the label element
//     //     const labelElement = document.getElementById('demo-simple-select-filled-label');
    
//     //     // if (labelElement) {
//     //     //     // Update the data-shrink attribute
//     //     //     labelElement.setAttribute('data-shrink', 'true');
//     //     // }
    
//     //     // if (selectRef.current) {
//     //     //     selectRef.current.focus();
//     //     // }
//     // };
    
//     const handleIconClick = () => {
//         console.log('Handling icon click');
    
//         // Use getElementsByClassName to get a collection of elements with the specified class
//         const elements = document.getElementsByClassName('MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-colorPrimary Mui-focused MuiInputBase-formControl  css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root');
    
//         // If there are multiple elements with the same class, choose the one you want to interact with
//         const element = elements[0]; // You may need to adjust this based on your specific HTML structure
    
//         // Trigger a click event on the selected element
//         if (element) {
//             element.click();
//         }
//     };

//     // const handleIconClick = () => {
//     //     // Use getElementById to get a reference to the label element with the specified ID
//     //     // const labelElement = document.getElementById('demo-simple-select-filled-label');
    
//     //     // Use getElementById to get a reference to the Select element with the specified ID
//     //     const selectElement = document.getElementById('demo-simple-select-filled');
    
//     //     // Trigger a click event on the label element
//     //     // console.log('labelElement',labelElement);
//     //     // if (labelElement) {
//     //     //     labelElement.click();
//     //     // }
    
//     //     // Trigger a click event on the Select element
//     //     console.log('selectElemen',selectElement)
//     //     if (selectElement) {
//     //         selectElement.click();
//     //     }
//     // };
    
    


//     return (
//         <div className={props.className}  style={{border :'1px solid #D9E1E1' , padding : '1px 8px' , borderRadius : '10px' }}>
//             <FormControl variant="standard" sx={{ m: 1, minWidth: '100%',top:'-5px'}}>
//           {  props.placeholder  && <InputLabel id="demo-simple-select-filled-label"  > {props.placeholder || ''}  </InputLabel>}
       
          

//                 <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     //value={age}
                   
//                     value={props.defaultValue && selectedValue}
//                     //defaultValue={age}
//                     IconComponent={React.Fragment}
//                     style={{ maxHeight: '200px', overflowY: 'auto' }}
//                     //onChange={handleChange}
                    
//                     // onChange={handleValueChange}
//                     onChange={props.onChange}
                   
//                 >
//                     {props?.value && 
//                         props?.value.map((item, index) => {
//                             //console.log('items', props?.value);
//                             return <MenuItem  key={index} value={item.value || item.title} selected={item.value || item.defaultValue}>{item.title || item.value}</MenuItem>
//                         })
//                     }
//                 </Select>
//                 {props.icon &&
                
//                 <div onClick={handleIconClick}
//                     style={{ position: 'absolute', top: props.top ? props.top : '17px', right: props.right ? props.right : '20px', width: 'fit-content',cursor:'pointer'}}>{props.icon}</div>}

//             </FormControl>
//         </div>
//     );

    
// }


import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';  // Import Menu component

export default function CustomDropDown(props) {
    const [selectedValue, setSelectedValue] = React.useState(props.defaultValue || '');

    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className={props.className} style={{ border: '1px solid #D9E1E1', borderRadius: '10px' }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', top: '-5px' }}>
                {props.placeholder && <InputLabel id="demo-simple-select-filled-label">{props.placeholder}</InputLabel>}

                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectedValue}
                    onChange={handleValueChange}
                    // IconComponent={() => <ArrowDropDownIcon />}
                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                    MenuComponent={Menu}  // Use Menu component directly
                    MenuProps={{ style: { maxHeight: '200px' } }} // Set styles for the Menu
                >
                    {props?.value &&
                        props?.value.map((item, index) => (
                            <MenuItem key={index} value={item.value || item.title} selected={item.value || item.defaultValue}>
                                {item.title || item.value}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    );
}


// const UploadImage = async ({payload, desiredPath}) => await axios.post(env.API_URL+service+'/upload/image?desiredPath='+desiredPath, payload, {headers: await MainService.getTokenHeader()})


// import React, { useState, useEffect, useRef } from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// export default function CustomDropDown(props) {
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [selectedValue, setSelectedValue] = useState(props.defaultValue || '');

//     const selectRef = useRef(null);

//     const handleValueChange = (event) => {
//         setSelectedValue(event.target.value);
//         handleCloseMenu();
//         if (props.onChange) {
//             props.onChange(event);
//         }
//     };

//     const handleIconClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };

//     const handleCloseMenu = () => {
//         setAnchorEl(null);
//     };

//     useEffect(() => {
//         setSelectedValue(props.defaultValue || '');
//     }, [props.defaultValue]);

//     return (
//         <div className={props.className} style={{ border: '1px solid #D9E1E1', padding: '1px 8px', borderRadius: '10px' }}>
//             <FormControl variant="standard" sx={{ m: 1, minWidth: '100%', top: '-5px' }}>
//                 {props.placeholder && <InputLabel id="demo-simple-select-filled-label">{props.placeholder}</InputLabel>}

//                 <Select
//                     labelId="demo-simple-select-filled-label"
//                     id="demo-simple-select-filled"
//                     value={selectedValue}
//                     onChange={handleValueChange}
//                     ref={selectRef}
//                     IconComponent={() => (
//                         <div
//                             onClick={handleIconClick}
//                             style={{
//                                 cursor: 'pointer',
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 padding: '5px',
//                                 border: '1px solid #D9E1E1',
//                                 borderRadius: '5px',
//                             }}
//                         >
//                             {props.icon}
//                         </div>
//                     )}
//                 />

//                 <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu} style={{ maxHeight: '200px', overflowY: 'auto' }}>
//                     {props.value &&
//                         props.value
//                             .filter((item) => item.title.length > 0) // Filter out items without content
//                             .map((item, index) => (
//                                 <MenuItem key={index} onClick={() => handleValueChange({ target: { value: item.value } })}>
//                                     {item.title}
//                                 </MenuItem>
//                             ))}
//                 </Menu>
//             </FormControl>
//         </div>
//     );
// }







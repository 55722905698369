var PngIcons = {


    // logo:'/images/Logo.png',
    logo:'/images/Frame.png',
    bigimg:'/images/bigimage.png',
    protect:'/images/protect.png',
    arrow:'/images/arrow.png',
    world:'/images/world.png',
    secondimg:'/images/secondimg.png',
    children:'/images/children.png',
    thirdimg:'/images/thirdimg.png',
    fourthimg:'/images/fourthimg.png',
    footerlogo:'/images/footer.png',
    imagebb:"/images/imagebb.png",
    newimgfour:'/images/newimgaefour.png',
    imagebc:"/images/imagebc.png",
    imagebd:"/images/imagebd.png",
    twitter:'/images/twitter.png',
    newimga:'/images/newImage.png',
    newimgb:'/images/newimga.png',
    newimgc:'/images/newimgb.png',
    facebook:'/images/fb.png',
    likedin:'/images/linkedin.png',
    mobile:'/images/mobilegroup.png',
    pattern:'/images/Pattern.png',
    lingkarain:'/images/Lingkaran.png',
    pic:'/images/pic.png',
    botpng:'/images/bott.png',
    dotpng:'/images/dotimage.png',
    aboutpica:'/images/aboutpica.png',
    aboutpicb:'/images/aboutpicb.png',
    aboutpicc:'/images/aboutpicc.png',
    blackcoal:'/images/blackRectangle.png',
    // servicepica:'/images/servicepica.png',
    advicea:'/images/adviceA.png',
    adviceb:'/images/adviceb.png',
    advicec:'/images/advicec.png',
    adviced:'/images/adviced.png',
    assetA:'/images/assetsA.png',
    assetB:'/images/assetB.png',
    assetC:'/images/assetc.png',
    assetd:'/images/assetsD.png',
    newassetA:'/images/newassetA.png',
    newassetB:'/images/newasserb.png',
    newassetC:'/images/newassetc.png', 
    newassetD:'/images/newassetd.png',
    servicepica:'/images/loloo.png',
    servicepicb:'/images/servicepicb.png',
    servicepicc:'/images/servicepicc.png',
    assesmentpica:'/images/assesmentpica.png',
    assesmentpicb:'/images/assesmentpicb.png',
    assesmentpicc:'/images/assesmnentpicc.png',
    mask:'/images/mask.png',
    tiktik:'/images/tiktik.png',
    copper:'/images/Picture.png',
    message:'/images/envelope.png',
    phone:'/images/phonrnew.png',
    location:'/images/newmarker.png',
    social:'/images/newsocial.png',
    slider:'/images/Testimonial/Slider controls/slider.png',
    twwo:'/images/twowo.png',

}


export default PngIcons;
import react, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Accordion from 'react-bootstrap/Accordion';
import PngIcons from './icons/PngIcons';
import Dropdown from 'react-bootstrap/Dropdown';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';


const About = () => {
  const navigate =useNavigate()

  const clickconstact =()=>{
    navigate('/contact')

  }
  const financialServicesGuidePdfPath = '/ANIG-FSG-Version-3-03.03.23-Nexus.pdf';
  return (
    <div className='w-100' id='aboutcontainer' >

     <Navbar expand="lg" className="bg-body-tertiary">
        <Container className='navbarmain' fluid >

          <Navbar.Brand href="#"><img className='logoimg' style={{
            width: '167px',
            //height: '40px'
          }} src={PngIcons.logo} /></Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 gap"
              style={{ maxHeight: '100px', marginLeft: 'auto', gap: '58px' }}
              navbarScroll
            >
              <Nav.Link  className='nav' href="/">Home

              </Nav.Link>

              <Nav.Link style={{ borderBottom: '2px solid  #FE5200' }} className='nav' href="/about">About Us

              </Nav.Link>

            
              <Nav.Link  className='nav' href="/assesment">Advisory

              </Nav.Link>
     

              <Nav.Link className='nav' href="/service">Books

              </Nav.Link>
        

            </Nav>
            <Form >
         

              <Button onClick={clickconstact}  className='signin'  >Contact Us</Button>
              {/* <div className='signin'> Free Assessment</div> */}

            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>

      <div className='first_div' style={{ width: '100%', margin: 'auto',marginTop:'-8px' }}>
          <div className='aboutus'>
          You work hard. We make your money work harder.
          </div>
          <div className='home'>Home  <span className='span' > {'>'}  About Us</span></div>
          <div>
                                <img className='tiktik' src={PngIcons.tiktik} />

                                </div>

        </div>


     

        <div className='fifth_div' style={{ width: '90%', margin: 'auto', paddingTop: '104px', paddingBottom: '61px' }}>

          <div className='dblock' style={{ display: 'flex', gap: '97px' }}>
       

            <div className='nomt' style={{marginTop:'95px'}}>
             

              <div className='aimtext'>
              Chart your own financial future.

 







             


                <div className='aimpara'>
                We’re not just accountants. We specialise in strategic advice and personalised service that is tailored to you and your business. From small businesses to tradies, trusts to individuals – at any stage of your career, we put you in control of your finances
                </div>

                <div className='aimpara' style={{ marginTop: '10px' }}>
                Get more from your money. At Blacksalt, we unlock the earning potential in your finances, now. Whether you need cost-effective accounting and bookkeeping, advice on how to reduce your tax, or help setting up your self-managed super fund, we’ve got you covered.
                </div>
              
              </div>
            </div>
            <div >
              <div >
                <img className='verynewaimimg' style={{
                  width: '498px',
                  //height:'705px'

                }} src={PngIcons.blackcoal} />
              </div>
            </div>





          </div>
        </div>



         

        <div className='sixth_div' style={{ width: '90%', margin: 'auto', paddingTop: '60px', paddingBottom: '94px' }}>
        <div className='dblock' style={{ display: 'flex', gap: '97px' }}>
       

       <div>
        

         <div className='aimtext'>
         You want to take control of your 
financial future. We show you how.


           <div className='aimpara'>
           We give you full control over your super, your investments and your financial future. We guide you through setting up your own self-managed super fund, and provide end-to-end financial and accounting services if you need it
           </div>

           <div className='aimpara' style={{ marginTop: '10px' }}>
           And if you don’t? Our divisions work independently, so you can choose which services you want. Just starting out? Check out our Advisory services. Drowning in the admin of running your own company? Our Books division takes the stress out of accounts, from payroll to BAS and everything in between.
           </div>
         
         </div>
       </div>
       <div style={{marginTop:'120px'}} >
         <div className='aimpara' >
         No stress, no exorbitant fees, no jargon. Just straight-forward advice, tailored to your needs and financial goals. At Blacksalt, we make super simple, with integrated, personalised services that extract maximum return for every dollar.


          
         </div>

         <div className='aimpara' style={{ marginTop: '10px' }}>We do things differently at Blacksalt. We don’t hide behind contracts and clauses, and you’re never just a number with us.</div>
       </div>





     </div>
       
        </div>

    

        <div className='newthird_div' style={{ width: '90%', margin: 'auto', paddingTop: '64px', paddingBottom: '64px' }}>


<div className='clienttext'>
    Our Clients Speak

    <div className='clientpara'>
        We have been working with clients around the world
    </div>
</div>


<Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Transforming Finances, Saving Time and Money
                        <div className='clientboxpara'>
                        Black Salt transformed our financial management. Their accounting expertise has saved us both time and money. Highly recommended!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Sarah J.
                        <div className='cooperpara'>
                        Small Business Owner
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    SMSF Made Simple and Stress-Free
                        <div className='clientboxpara'>
Managing my SMSF was daunting until I found Black Salt. Their team made it simple and stress-free. I couldn't be happier with the service.
                            
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Michael R.
                        <div className='cooperpara'>
                        Retiree
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Beyond Accounting: Partners in Growth
                        <div className='clientboxpara'>
As a growing startup, we needed precise financial guidance. Black Salt delivered beyond our expectations. They're not just accountants, they're growth partners. 
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Emily T. 
                        <div className='cooperpara'>
                        Tech Entrepreneur
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Maximizing Retirement Savings with Expert SMSF Advice
                        <div className='clientboxpara'>
Black Salt's SMSF advice has been invaluable. They've helped maximize my retirement savings while ensuring full compliance. True professionals!
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    David L.
                        <div className='cooperpara'>
                        Self-Managed Investor
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Proactive Approach, Unmatched Attention to Detail
                        <div className='clientboxpara'>
Switching to Black Salt was the best decision for our company's finances. Their proactive approach and attention to detail are unmatched.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://png.pngtree.com/png-vector/20220817/ourmid/pngtree-women-cartoon-avatar-in-flat-style-png-image_6110776.png'} alt='cooper' />
                    <div className='coopertext'>
                    Lisa M.
                        <div className='cooperpara'>
                        CFO
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                    Navigating SMSF Complexities with Confidence
                        <div className='clientboxpara'>
I was lost in the complexities of SMSF regulations. Black Salt provided clear, expert guidance. Now I feel confident about my financial future.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={'https://cdn.iconscout.com/icon/free/png-256/free-avatar-370-456322.png?f=webp'} alt='cooper' />
                    <div className='coopertext'>
                    Robert K.
                        <div className='cooperpara'>
                        SMSF Trustee
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel>
{/* <Carousel >
    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            The Nexus Financial Advisors  team have given us honest and open advice. Their fees are reasonable and they have a great team who are always ready to assist. I am really looking forward to working with them
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Drew W
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team at  Nexus Financial Advisors are very professional. They contacted me promptly and were very informative and thorough. Would recommend them in the future if you’re wanting help with your superannuation or finances
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Amanda Crouch
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Very supportive, informative and helpful. Let’s see if the rollover is worth it.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Charles Nielsen
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>

    <Carousel.Item>
        <div className='dblock' style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '40px', paddingBottom: '40px' }}>
            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Efficient Collaborating
                        <div className='clientboxpara'>
                            Really supportive and easy process to complete
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Jessica Tones
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Intuitive Design
                        <div className='clientboxpara'>
                            The team has always been very helpful and transparent in what they do. I have had multiple interactions and have always been happy with the outcome.
                            The personal touch makes you feel like you're not another number, compared to other places. Highly recommended to give them a call and find out what they can do for you.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        michael Ross
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>


            <div>
                <div className='clientbox'>
                    <div className='clientboxtext'>
                        Mindblowing Service
                        <div className='clientboxpara'>
                            Second to none. The level of professionalism and efficiency will make me keep working with Nexus.
                            Brendan was fantastic and went out of his way to speak with me and assist.
                        </div>
                    </div>
                </div>
                <div>
                    <img className='cooper' src={PngIcons.copper} alt='cooper' />
                    <div className='coopertext'>
                        Cam Ingles
                        <div className='cooperpara'>
                            CEO at ABC Corporation
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Carousel.Item>



</Carousel> */}


</div>
    


        <div className='footer-div'>

          <MDBFooter className='text-center text-lg-start text-muted'>
            <section className>


            </section>

            <section className=''>
              <MDBContainer >

                <MDBRow  >
                  <div className='dblock' style={{ display: 'flex', gap: '176px' }}>
                    <div style={{ width: '50%' }}>
                      <MDBCol >
                        <h6 className=' mb-4'>
                          <MDBIcon />
                          <img style={{
                            width: '227px',

                          }} src={PngIcons.footerlogo} />
                        </h6>
                        {/* <p className='footerpara'>
                        Future Financial Wealth T/As  Nexus Financial Advisors 
Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)
                        </p> */}
                        <div style={{ display: 'flex', gap: '20px', marginTop: '40px' }}>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.facebook} />
                          </div>
                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.twitter} />
                          </div>

                          <div>
                            <img style={{
                              width: '48px',
                              height: '48px'
                            }} src={PngIcons.likedin} />
                          </div>
                        </div>
                      </MDBCol>
                    </div>
                    <div className='dblock textleft newgap' style={{ display: 'flex', gap: '190px' }}>

                      <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4' >
                        <h6 className='footertext'>Sitemap</h6>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Home
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Services
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Assessment
                        </p>

                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />Reviews
                        </p>



                      </MDBCol>

                      <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
                        <h6 className=' footertext'>Info</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          About Us
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Blogs
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Contact
                        </p>





                      </MDBCol>

                      <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
                        <h6 className='footertext'>Security</h6>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          FAQ
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' />
                          Terms & Conditions
                        </p>
                        <p className='footerpara'>
                          <MDBIcon color='secondary' icon='home' /> Privacy Policy
                        </p>

                      </MDBCol>
                    </div>
                  </div>

                  {/* <div className='newfooterpara'>
                    <div>ABN: 86 663 229 847

                      <div>ACN: 663 229 847</div>
                    </div>

                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>

                    <a
        href={financialServicesGuidePdfPath}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: 'underline', color: 'blue' }}
      >
        Financial Services Guide
      </a>
                    </div>
                    <p>Future Financial Wealth T/As  Nexus Financial Advisors </p>
                    


                    <p>Nexus Financial Advisors Corporate Authorised Representative (No 001304365 ) of Australia National Investment Group Pty Ltd AFSL 522028 (The Licensee)</p>


                  </div> */}
                </MDBRow>

              </MDBContainer>
            </section>


          </MDBFooter>

        </div>

      
      </div>
    </div>
  )
}


export default About;